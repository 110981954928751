.vendor_header{
	display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 5%;
}
.vendor_left__header,
.vendor_middle_header,
.vendor_right__header{
	  display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
}

/*.vendor{
  display: block;
}*/
.vendor_menu__left,.vendor_menu__right{
	font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    text-decoration: none;
    text-transform: none;
    line-height: 24px;
    color: #8f250cff;
}
.vendor_header_link{
  text-decoration: none !important;
}
.menu__button{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 140px;
    height: 40px;
    cursor: pointer;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 24px 24px 24px 24px;
    background: #8f250cff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
}
.vendor_menu__text{
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;

  text-decoration: none;
  text-transform: none;

  line-height: 24px;
  color: #e9d8a6ff;
}
.blog-section {
  width: 100%;
/*  height: 1739px;*/
  transform: rotate(0deg);
  background: #f2ecd9ff;
}
.rectangle-3 {
  height: 180px;
  margin: 5px 5%;
  transform: rotate(0deg);
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
}
.nodeBg-19043586 {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../Assets/service-images/image2.png");
  opacity: 1;
}
.nodeBg-19043620 {
  position: absolute;

  width: 1217px;
  height: 684.9999755382547px;
  left: -0.9513565935194492px;
  top: -355.69787207730275px;
  background-color: transparent;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../Assets/service-images/image_2.png");
  opacity: 1;
}
.frame-13 {
  height: 89px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 5%;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.email-capture {
  display: flex;
  align-items: center;
  height: 48px;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.input-field-2,
.input-field-1 {
  width: 181.5px;
  height: 48px;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.buttonsbutton-1 {
  width: 50px;
  height: 48px;
  cursor: pointer;
  transform: rotate(0deg);
  border-radius: 0px 24px 24px 0px;
  background: #8f250cff;
}
.input-1-14 {
  width: 181.5px;
  height: 48px;
  transform: rotate(0deg);
  border-radius: 24px 0px 0px 24px;
  background: #dec4b066;
}
.text-input-1-8 {
  position: absolute;
  width: 167.5px;
  height: 24px;
  display: flex;
  gap: .41rem;
  top: 10px;
  left: 14px;
  opacity: 1;
  z-index: 0;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.input-1-85 { 
  display: flex;
  justify-content: left;
  align-items: center;
  width: 181.5px;
  height: 48px;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
  background: #dec4b066;
}
.text-1-20 {
  font-size: 15px;
  font-family: Avenir Next;
  font-weight: 500;

  line-height: 24px;
  color: #190702ff;
}
.heading-1-4209-0 {
    font-size: 23px;
    font-family: Mermaid;
    font-weight: 700;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.24px;
    line-height: 44px;
    color: #8f250cff;
}
.icon--searchout-1-2890 {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 14px;
  left: 11.5px;
  opacity: 1;
  z-index: 0;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.nodeBg-I19043600_3471597214_3231752 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: transparent;
  background-size: contain;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF9SURBVHgBpZJPTsJAFMbfG/7U4KYuWLCDjYYd3ABPQNzJwognIJ6AegM8ARrTssUb6AlwYdJ2RRcmWFzYjYYAM8+ZooS0pUb5kmam89783puZDyFBL89mLZMRupqXqmcP8Itw82fqmB25YhChvrHsMcSb4lHrahuErQHuoE+APSB8AsFPkFFdED8WBI+CyPAdawRp8t1BVyaRb5tGUnxim+20+AriWGMJ6kOKXh2z57vW+3jU12PHmdh3DTmWgdN9GoRIDIFAL2haIwb5mSwEeZCqbBgXSPFOOF8GapJjWE5DcD4LNzPCIAbZX4CHSAFmsJkGyef2OqtO5OtFIQf1i4ALuCai9tS1zpMA6t5UHKRfStWWF42vzfbtgxrKRC6WtwBLj/O8ruVYU/kkrMhYr3h4erkVsqpoGtKd3WgSQdhpoGLyM6LuxegG5YOClm0IZOFFzmbzYUUeebNIEuhPUiDl3jfpcthFURDCP6VAGQadj895BXbRxB6U1fgFVzW9T6hXfYcAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
}
.heading-1-4209 {
    width: 454px;
    height: 44px;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
}
.heading-1-1617 {
  width: 354px;
  height: 61px;
  transform: rotate(0deg);
  text-align: left;
  line-height: 0px;
}
.heading-1-1617-0 {
  font-size: 23px;
  font-family: Mermaid;
  font-weight: 700;

  letter-spacing: 0.24px;
  line-height: 44px;
  color: #8f250cff;
}
.icon-wrap-1-828 {
  position: absolute;
  width: 24px;
  height: 28px;
  top: 0px;
  left: 0px;
  opacity: 1;
  z-index: 0;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.icon-1-684 {
  position: absolute;
  width: 14px;
  height: 12px;
  top: 6px;
  left: 5px;
  opacity: 1;
  z-index: 0;
  transform: rotate(0deg);
  transform-origin: 594px 639.5px;
  overflow: hidden;
  box-sizing: border-box;
}
.nodeBg-I19043595_3463404543 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: transparent;
  background-size: contain;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB9SURBVHgBnZDRCYAwDESTFMEx6p9rOFIncaSuIfjTJQRBiraWCi1NwN5X4CXH5RAqrfOowSsbZ7MfE8eoDVDzholRG9wOLr9IDDlg3Okkhj1HKSoDXgmMoFMEKpYQHKPzoOyqQ7wsgfWXkx3+Hn8/mi0sVdEkVpRTLpSq2QM+Jp6J8Q0J4wAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
}
.container-1-988 {
  margin: 5px 5%;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.content-1-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.blog-post-card-1-28 {
  width: 384px;
  height: 643px;
  margin-bottom: 2rem;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.image-1-19 {
  width: 384px;
  height: 443px;
  transform: rotate(0deg);
  overflow: hidden;
  border-radius: 24px 24px 24px 24px;
}
.nodeBg-I19043604_18532085 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: auto;
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
}
.cart-1-1242 {
  position: absolute;
  width: 44px;
  height: 44px;
  top: 10px;
  left: 325px;
  opacity: 1;
  z-index: 1;
  transform: rotate(0deg);
  border-radius: 32px 32px 32px 32px;
  background: #e9d8a6ff;
}
.icon--shoppingo-1-1882 {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 10px;
  left: 10px;
  opacity: 1;
  z-index: 0;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.nodeBg-I19043604_2345684_2345677_351646 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: transparent;
  background-size: contain;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAYAAACAl21KAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFFSURBVHgB7VQxTsNAENw7bxASQTLiA0YoKB38ANJR+gnxD7iODvIAZOUHfIEyFeEHLhEp8AuQiyCh2L5lfVGMcc6JgiKqrLSavZv1eE7ntYAVEXquq1upT1omTp5GKv6Km3pFo0inHTLc1Lrv1dt0YOt3rCJn7TuGWyAYg6AB4wuLdFnJvz7ec0cfs9FaR2F334Mc3wEoVpPPk5rLZ4YrSPFIxUlS5WRdKMvQK1ATDOucJnoyBWYXS46MA40h23fnW8RCohCLOJPf7RZOMM4wwMIBCvAr2oti6a1WjozDYXk0rSlQk6nQQEF1Xc0Fxw/3zJqgvEEJWwqsb0gnH0OGPZm3oiaOM1orpF7N1xuDJVZxWzvaTugfhcrrlxIuHzoHsElIAedmRAohFJTMZ0j02V4fNgn6Kc0UhqeHPkjtwh+D/1uP3wz8fTHEil+HAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: center;
}
.vector-1-15 {
  position: absolute;
  width: 16.5px;
  height: 18.75px;
  top: 2.625px;
  left: 3.75px;
  opacity: 1;
  z-index: 0;
  transform: rotate(0deg);
  transform-origin: 269.25px 489.875px;
  overflow: hidden;
}
.rate-1-320 {
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  height: 53px;
  top: 390px;
  left: 0px;
  opacity: 1;
  z-index: 0;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
  background: #8f250c33;
  backdrop-filter: blur(6px);
}
.text-1-117 {
  padding: 1rem;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.this-is-a-text-1-244 {
  font-size: 13px;
  font-family: Avenir;
  font-weight: 400;

  text-decoration: underline;
  letter-spacing: 0.28px;
  line-height: 22px;
  color: #ffffffff;
}
.frame-5-1-4653 {
  transform: rotate(0deg);
  padding: 1rem;
  display: flex;
  border-radius: 0px 0px 0px 0px;
}
.rate-star-1-756 {
  width: 20px;
  height: 20px;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.nodeBg-I19043604_18532088_39611242_35172 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: transparent;
  background-size: contain;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE7SURBVHgBnVRLTsMwEJ2JHVCFkCLRA6RIXbAiPUJPgjhJyg3oCThDTwA3aHdIdNGyr4QXkRCNw5BxP9hNa7d9kpXJzNPz83hkgABonCS8QjwZIuhr8UKELNT38dBX/H5PUiHljOOqFmp1F2+HuJFPSMQi38YAOZzjyHazgc9Vo0fc2PIqTjH6zYncGrsqp22QhZ5gTynHkdmZj0CYAVIKq8aGgaRq7qTeS10U+hFpVjsoxRgQUzgHRPOqqvoRdpTigBNwKtYirTs13zbbHFGI16OdWSL869za0WI7IgxnjtaFEQQRjWyRhpAB4j2EgNTg7BGiDMJocJwemVHQ8svKKAIcrvTpwe5dLHSKt+pzryOtZfYvAE9xUXUuu4sBLzMiSMMNd1mKHhwC39rPtD3wvT/MWX7cPPPXzv8B4XWWtwf1aLwAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
}
.vector-1-159 {
  width: 16.54205322265625px;
  height: 15.79681396484375px;
  transform: rotate(0deg);
  transform-origin: 591.27099609375px 104.66693115234375px;
  overflow: hidden;
}
.text-1-2077 {
  width: 20px;
  height: 22px;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.this-is-a-text-1-810 {
  width: 20px;
  height: 22px;
  transform: rotate(0deg);
  text-align: left;
  line-height: 0px;
}
.this-is-a-text-1-810-0 {
  font-size: 13px;
  font-family: Inter Tight;
  font-weight: 400;

  letter-spacing: 0.28px;
  line-height: 22px;
  color: #ffffffff;
}
.rate-star-5-4779 {
  width: 20px;
  height: 20px;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.nodeBg-I19043604_18532092_39611239 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: transparent;
  background-size: contain;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/figma-plugin-a7287.appspot.com/o/user-images%2F26-jan-2024%2Fabiywasihun1706244140275%2Fimage-I190-43604_185-32092_396-11239.png?alt=media&token=0);
  background-repeat: no-repeat;
  background-position: center;
}
.mask-group-1-105 {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0px;
  left: 0px;
  opacity: 1;
  z-index: 1;
  transform: rotate(0deg);
  transform-origin: 0px 0px;
  overflow: hidden;
}
.content-1-322 {
  opacity: 1;
  z-index: 1;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.olivia-rhye--20-1-1175 {
  width: 384px;
  transform: rotate(0deg);
  text-align: left;
  line-height: 0px;
}
.olivia-rhye--20-1-1175-0 {
  font-size: 13px;
  font-family: Avenir;
  font-weight: 500;

  line-height: 20px;
  color: #8f250cff;
}
.heading-and-ico-1-663 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 384px;
  height: 32px;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.heading-1-160 {
  width: 344px;
  height: 32px;
  transform: rotate(0deg);
  text-align: left;
  line-height: 0px;
}
.heading-1-160-0 {
  font-size: 23px;
  font-family: Mermaid;
  font-weight: 700;

  line-height: 32px;
  color: #8f250cff;
}
.icon-wrap-1-582 {
  width: 24px;
  height: 28px;
  transform: rotate(0deg);
  border-radius: 0px 0px 0px 0px;
}
.nodeBg-I19043604_18532101_3463404516 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: transparent;
  background-size: contain;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABoSURBVHgBldHLCYAwDIDhpHoQXCIXwTUcyUk6kqOYMQQpFQ2I0leSSw/9floI+mncAZBANZEdmAYJa9d+HghCt31/4HQ4cjVI8BmWYpDDKx+cDVr4F2jwG2jxE1iwvGDA9/RyaLDs4gKDqFZNhkQrEAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
}
.icon-1-296 {
  width: 12px;
  height: 12px;
  transform: rotate(0deg);
  transform-origin: 242px 11.5px;
  overflow: hidden;
  box-sizing: border-box;
}
.supporting-text-1-2849 {
  width: 384px;
  height: 48px;
  transform: rotate(0deg);
  text-align: left;
  line-height: 0px;
}
.supporting-text-1-2849-0 {
  font-size: 15px;
  font-family: Avenir;
  font-weight: 400;

  line-height: 24px;
  color: #8f250cff;
}
.buttonsbutton-1-225 {
  width: 126px;
  height: 44px;
  margin-top: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0deg);
  border-radius: 24px 24px 24px 24px;
  box-sizing: border-box;
  border: 1px solid #8f250cff;
}
.text-1-1-0 {
  font-size: 15px;
  font-family: Avenir;
  font-weight: 800;

  line-height: 24px;
  color: #8f250cff;
}


@media all and (min-width: 1024px){ }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) { 
  .vendor_header{
  padding: 18px 1%;
  justify-content: flex-start;
  gap: 8px;
} 
.vendor_left__header,
.vendor_middle_header,
.vendor_right__header{
  gap: 7px;
}
.vendor_logo__header{
  width: 83px;
  height: 40px;
  object-fit: contain;
}
.vendor_menu__left,.vendor_menu__right{
  font-size: 9px;
}
.menu__button{
    width: 84px;
    height: 29px;
}
.vendor_menu__text{
  font-size: 9px;
}
.frame-13{
  flex-direction: column;
}
.content-1-1{
  grid-template-columns: repeat(1, 1fr);
}
.blog-section{
  margin-bottom: 2rem;
}
.heading-1-1617-0{
  font-size: 17px;
}
.input-field-2, .input-field-1{
  width: 152.5px;
}
.supporting-text-1-2849,
.olivia-rhye--20-1-1175,
.heading-and-ico-1-663,
.image-1-19{
  width: 348px;
}
.cart-1-1242{
  left: 301px;
}
}

@media all and (max-width: 480px) {
  .vendor_header{
  padding: 18px 1%;
  justify-content: flex-start;
  gap: 8px;
} 
.vendor_left__header,
.vendor_middle_header,
.vendor_right__header{
  gap: 7px;
}
.vendor_logo__header{
  width: 83px;
  height: 40px;
  object-fit: contain;
}
.vendor_menu__left,.vendor_menu__right{
  font-size: 9px;
}
.menu__button{
    width: 84px;
    height: 29px;
}
.vendor_menu__text{
  font-size: 9px;
}
.frame-13{
  flex-direction: column;
}
.content-1-1{
  grid-template-columns: repeat(1, 1fr);
}
.blog-section{
  margin-bottom: 2rem;
}
.heading-1-1617-0{
  font-size: 17px;
}
.input-field-2, .input-field-1{
  width: 152.5px;
}
.supporting-text-1-2849,
.olivia-rhye--20-1-1175,
.heading-and-ico-1-663,
.image-1-19{
  width: 348px;
}
.cart-1-1242{
  left: 301px;
}
}