.content-1-vd{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 308px;
}
.blog__container{
      padding: 20px 5%;
}
.search__blog{
    margin-top: 2rem;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.input__blog{
  height: 31px;
  border: none;
}
.package_form{
  width: 90%;
}
.top_package{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select__category{
  padding: .41rem;
  border: none;
  border-radius: 1rem;
  width: 100%;
}
.package_modal{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 30%;
    padding: 2rem;
    align-items: center;
    background: #F2ECD9;
    position: absolute;
    top: 3%;
    left: 30%;
}
.package_title{
    font-size: 23px;
    font-family: Mermaid;
    font-weight: 700;
    margin-bottom: 1rem;
    letter-spacing: 0.24px;
    line-height: 44px;
    color: #8f250cff;
}
.package_button{
    font-size: 18px;
    font-family: Avenir;
    line-height: 24px;
    padding: .41rem 2rem;
    background: #8f250cff;
    color: white;
    cursor: pointer;
    text-align: center;
    border-radius: 24px;
}
.input_media{
    position: relative;
    background: #EADCC9;
    cursor: pointer;
    height: 150px;
    border-radius: 24px 24px 24px 24px;
}
.package_img{
    position: absolute;
    top: 72px;
    width: 28px;
    left: 45%;
}
.package_close{
  cursor: pointer;
}
.input_radios{
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.package_detail{
    position: absolute;
    top: 68%;
    left: 22%;
    font-size: 15px;
    font-family: Mermaid;
    letter-spacing: 0.24px;
    color: #8f250cff;
}
.buttonsbutton-vd{
  width: 40px;
  height: 38px;
  cursor: pointer;
  transform: rotate(0deg);
  background: #8f250cff;
}
.modal_container{
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.images_list{
  display: flex;
  gap: 1rem;
}
.image_card{
  width: 100%;
}
.image_close{
  position: absolute;
  top: 23%;
  left: 35%;
}
.icon--searchout-1-vd{
    position: absolute;
    width: 18px;
    height: 18px;
    top: 9px;
    left: 10.5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
}
.blog__title{
    font-size: 23px;
    font-family: Mermaid;
    font-weight: 700;
    letter-spacing: 0.24px;
    line-height: 44px;
    color: #8f250cff;
}
.blog__cards{
  display: flex;
  justify-content: space-around;
}
.blog-post-card-1-28-vd {
    width: 360px;
    margin-bottom: 2rem;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
.buttonsbutton-1-vd{
    width: 115px;
    height: 36px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0deg);
    border-radius: 24px 24px 24px 24px;
    box-sizing: border-box;
    border: 1px solid #8f250cff;
}
.text-1-1-0-vd,.heading-1-160-0-vd{
    font-size: 13px;
    font-family: Avenir;
    font-weight: 700;
    line-height: 24px;
    color: #8f250cff;
}
.heading-1-160-0-vd{
  font-size: 16px;
}
.content-1-322-vd{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
}
.image-1-19-vd{
    width: 360px;
    height: 180px;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 24px 24px 24px 24px;
}
@media all and (min-width: 1024px){ }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {
  .content-1-vd {
    flex-direction: column;
}
.blog__cards{
  flex-direction: column;
}
}

@media all and (max-width: 480px) {
  .content-1-vd {
    flex-direction: column;
}
.blog__cards{
  flex-direction: column;
}
}