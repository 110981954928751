.heading-1-42091{
    width: 454px;
    height: 44px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
}
.heading-1-4209-01 {
    font-size: 23px;
    font-family: Mermaid;
    font-weight: 700;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.24px;
    line-height: 44px;
    color: #8f250cff;
}