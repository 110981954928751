.chat_container{
	padding: 20px 5%;
    margin-top: 3rem;
}
.chat_top{
	display: flex;
    gap: 2rem;
    border-bottom: 2px solid #8f250cff;
    margin-top: 2rem;
}
.chat_content{
	display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
.left_chat{
	width: 56%;
}
.left_status{
	background: #E9D8A6;
    border-radius: 10px;
    display: flex;
    gap: 2rem;
}
.active_status{
	background: white;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding: 0 1rem;
}
.left_show{
	margin-top: 1rem;
    font-weight: 800;
}
.show_names{
    cursor: pointer;
    font-size: 18px;
    font-family: Mermaid;
}
.active_names{
	color: #8f250cff;
}
.message_lists, .right_chat{
	border-radius: 10px;
    overflow: auto;
    box-shadow: 0px 0px 2px 1px #d7cdab;
}
.message_lists{
    height: 500px;
    padding: 0.51rem;
    margin-top: 1rem;
}
.right_chat{    
	width: 41%;
    height: 615px;
    overflow: auto;
    position: relative;
}
.message_detail_title{
	background: #8f250cff;
    color: white;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    padding: 1rem;
    font-family: Mermaid;
}
.message_detail_container{
        padding: 20px 5%;
}
.message_container{
	margin-bottom: 1rem;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0px 0px 2px 1px #d7cdab;
}
.sender_profile{
	display: flex;
    align-items: center;
    gap: 1rem;
}
.sender_img{
	width: 50px;
}
.message_sender{
	display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.sender_name{
	color: #8f250cff;
    font-weight: 600;
    font-size: 19px;
    font-family: Mermaid;
}
.sender_profession{
    color: #0c348f;
    font-size: 16px;
    font-family: Mermaid;
}
.last_message{
    font-size: 16px;
    font-family: Mermaid;
    letter-spacing: 0.24px;
    margin-top: .61rem;
    line-height: 27px;
    margin-left: 1rem;
}
.send_message{
	position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 89%;
    padding: 20px 5%;
}
.message_input{
	width: 90%;
    border: navy;
    border-radius: 7px;
    padding: .51rem;
    font-size: 16px;
}
.message_left{
	margin-top: 2rem;
}
.message_right{
	text-align: right;
    margin-top: 2rem;
}
.message_left_profile{
	width: 60%;
    display: inline-flex;
    justify-content: space-between;
}
.message_right_message, .message_left_message{
	display: inline-block;
    width: 60%;
    padding: .51rem;
    font-family: Mermaid;
}
.message_right_message{
    background: #8f250cff;
    color: #fff;
    border-radius: 10px 0px 10px 10px;
}
.message_left_message{
    background: whitesmoke;
    color: #000;
    border-radius: 0px 10px 10px 10px;
}
@media all and (min-width: 1024px){ }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) { 
    .chat_content{
        flex-direction: column;
    }
    .left_chat{
        width: 100%;
    }
    .right_chat{
        display: none;
    }
}

@media all and (max-width: 480px) {
    .chat_content{
        flex-direction: column;
    }
    .left_chat{
        width: 100%;
    }
    .right_chat{
        display: none;
    }
}