.frame-ab{
	padding: 20px 5%;
}
.content-1-ab{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 308px;
}
.blog-post-card-1-28-ab{
    width: 260px;
    margin-bottom: 2rem;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
}
.image-1-19-ab{
    width: 260px;
    height: 280px;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 24px 24px 24px 24px;
}