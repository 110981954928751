.user_icon{
    border: 1px solid black;
    padding: 1% 2%;
    border-radius: 50%;
}
.portfolio_container{
	padding: 20px 5%;
}
.notification__header{
  width: 30px;
  height: 25px;
  object-fit: contain;
}
.top_section{
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.left_top_section{
	display: flex;
    align-items: center;
    gap: 1rem;
}
.user_logo{
	position: relative;
    cursor: pointer;
}
.vendor__pp{
    width: 140px;
    height: 140px;
    object-fit: contain;
    border-radius: 50%;
}
.user_ic{
	position: absolute;
    top: 72px;
    width: 28px;
    left: 64px;
}
.edit_ic{
	width: 24px;
    position: absolute;
    top: 123px;
    left: 135px;
}
.right_top_section{
	display: flex;
	gap: 1rem;
}
.portfolio_title{
    font-size: 23px;
    font-family: Mermaid;
    font-weight: 700;
    letter-spacing: 0.24px;
    line-height: 44px;
    color: #8f250cff;
}
.portfolio_desc,.card_media,.product_card,.package_card{
    font-size: 15px;
    font-family: Mermaid;
    letter-spacing: 0.24px;
    color: #8f250cff;
}
.card_media{
    position: absolute;
    top: 61%;
    left: 33%;
}
.card__title{
    font-size: 23px;
    font-family: Mermaid;
    font-weight: 700;
    letter-spacing: 0.24px;
    line-height: 44px;
}
.card_description{
    font-size: 15px;
    font-family: Mermaid;
    letter-spacing: 0.24px;
    /* color: #8f250cff; */
    line-height: 27px;
}
.cancel_button{
	background: #E9D8A6;
    width: 100px;
}
.cancel_text{
	color: #8f250cff;
}
.image_container{
	margin-top: 2rem;
}
.card_container{
	display: flex;
    height: 350px;
    gap: 1rem;
}
.card_one,.card_two{
	width: 20%;
    cursor: pointer;
    border-radius: 24px 24px 24px 24px;
    background: #EADCC9;
    position: relative;
}
.card_three{
	gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 20%;
}
.card_three_one,.card_three_two{
	height: 50%;
    cursor: pointer;
    border-radius: 24px 24px 24px 24px;
    background: #EADCC9;
    position: relative;
}
.card_four{
    position: relative;
	background: #EADCC9;
    width: 40%;
    cursor: pointer;
    border-radius: 24px 24px 24px 24px;
}
.media__style{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.plus_ic{
	width: 24px;
    position: absolute;
    top: 50%;
    left: 40%;
}
.about_continer{
	display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;
}
.about_left{
	display: flex;
    flex-direction: column;
    gap: .4rem;
    width: 50%;
}
.about_textarea_field{
	width: 595px;
    height: 176px;    
    border: #8f250cff;
    padding: .41rem;
    border-radius: 24px;
}
.text_lable{
	font-size: 15px;
    font-family: Mermaid;
    letter-spacing: 0.24px;
    color: #8f250cff;
}
.about_right{
	display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
}
.service_container{
    height: 250px;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}
.service_view_container{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.portfolio_content-1-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.6rem;
}
.left_service,.right_service{
	width: 50%;
	height: 100%;
}
.left_service_card{
    position: relative;
    background: #EADCC9;
    width: 40%;
    height: 80%;
    cursor: pointer;
    border-radius: 24px 24px 24px 24px;
}
.product_card{
	position: absolute;
    top: 61%;
    left: 18%;
}
.package_card{
    position: absolute;
    top: 61%;
    left: 28%;
}
.card_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px 24px 24px 24px;
}
/*{
	#EADCC9
}*/
@media all and (min-width: 1024px){ }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) { 
    .menu__lang{
        display: none;
    }
    .top_section{
    flex-direction: column;
    gap: 1rem;
    }
    .about_continer{
    flex-direction: column;
    align-items: normal;
    }
    .about_left{
        width: 100%;
    }
    .notification__header{
        width: 17px;
        height: 14px;
        object-fit: contain;
    }
 .portfolio_content-1-1 {
    grid-template-columns: repeat(1, 1fr);
}
}

@media all and (max-width: 480px) {
    .menu__lang{
        display: none;
    }
    .top_section{
    flex-direction: column;
    gap: 1rem;
    }
    .about_continer{
    flex-direction: column;
    align-items: normal;
    }
    .about_left{
        width: 100%;
    }
    .notification__header{
        width: 17px;
        height: 14px;
        object-fit: contain;
    }
 .portfolio_content-1-1 {
    grid-template-columns: repeat(1, 1fr);
}
}