@keyframes animation-19043614 {
  from {
    width: 1440px;
    height: 1802px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 1440px;
    height: 80px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 32;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-19043614 {
  animation: animation-19043614 0.001s linear 0s normal forwards;
}
@keyframes animation-1653093 {
  from {
    width: 1440px;
    height: 80px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 32;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 130;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #f9fafbff;
  }
}
.animationClass-1653093 {
  animation: animation-1653093 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14282 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 130;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #f9fafbff;
  }
  to {
    width: 1440px;
    height: 80px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 32;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClassBack-14282 {
  animation: animationBack-14282 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-14282 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 130;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #f9fafbff;
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-14282 {
  animation: animation-14282 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 130;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #f9fafbff;
  }
}
.animationClassBack-14259 {
  animation: animationBack-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-14259 {
  animation: animation-14259 0.001s linear 0s normal forwards;
}
@keyframes animationBack-14273 {
  from {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClassBack-14273 {
  animation: animationBack-14273 0.001s linear 0s normal forwards;
}
@keyframes animation-14273 {
  from {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 10;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-14273 {
  animation: animation-14273 0.001s linear 0s normal forwards;
}
@keyframes animation-16783 {
  from {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 10;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 10;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-16783 {
  animation: animation-16783 0.001s linear 0s normal forwards;
}
@keyframes animation-16783 {
  from {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 10;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-16783 {
  animation: animation-16783 0.001s linear 0s normal forwards;
}
@keyframes animationBack-14273 {
  from {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 10;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClassBack-14273 {
  animation: animationBack-14273 0.001s linear 0s normal forwards;
}
@keyframes animation-14273 {
  from {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-14273 {
  animation: animation-14273 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClassBack-14259 {
  animation: animationBack-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-14259 {
  animation: animation-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClassBack-14259 {
  animation: animationBack-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-14259 {
  animation: animation-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClassBack-14259 {
  animation: animationBack-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-14259 {
  animation: animation-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClassBack-14259 {
  animation: animationBack-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-14259 {
  animation: animation-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClassBack-14259 {
  animation: animationBack-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-14259 {
  animation: animation-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClassBack-14259 {
  animation: animationBack-14259 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-14259 {
  from {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-14259 {
  animation: animation-14259 0.001s linear 0s normal forwards;
}
@keyframes animationBack-14273 {
  from {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 177px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 125;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClassBack-14273 {
  animation: animationBack-14273 0.001s linear 0s normal forwards;
}
@keyframes animation-14273 {
  from {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-14273 {
  animation: animation-14273 0.001s linear 0s normal forwards;
}
@keyframes animationBack-14273 {
  from {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClassBack-14273 {
  animation: animationBack-14273 0.001s linear 0s normal forwards;
}
@keyframes animation-14273 {
  from {
    width: 145px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 128;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 320px;
    height: 96px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 108;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-14273 {
  animation: animation-14273 0.001s linear 0s normal forwards;
}
@keyframes animation-112870 {
  from {
    width: 320px;
    height: 96px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 108;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 320px;
    height: 96px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 108;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-112870 {
  animation: animation-112870 0.001s linear 0s normal forwards;
}
@keyframes animation-112870 {
  from {
    width: 320px;
    height: 96px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 108;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    width: 48px;
    height: 48px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 294;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-112870 {
  animation: animation-112870 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14824 {
  from {
    width: 48px;
    height: 48px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 294;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #6941c6ff;
    box-sizing: border-box;
    border-left: 1px solid #6941c6ff;
    border-right: 1px solid #6941c6ff;
    border-top: 1px solid #6941c6ff;
    border-bottom: 1px solid #6941c6ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    width: 320px;
    height: 96px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 108;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClassBack-14824 {
  animation: animationBack-14824 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-14275 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-14275 {
  animation: nullClass-14275 0.001s linear 0s normal forwards;
}
@keyframes animation-null14275 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 89px;
    height: 24px;
    top: 0px;
    left: 28px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-null14275 {
  animation: animation-null14275 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14275 {
  from {
    width: 89px;
    height: 24px;
    top: 0px;
    left: 28px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-14275 {
  animation: animationBack-14275 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-14283 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-14283 {
  animation: nullClass-14283 0.001s linear 0s normal forwards;
}
@keyframes animation-null14283 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 20px;
    height: 20px;
    top: 12px;
    left: 16px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null14283 {
  animation: animation-null14283 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14283 {
  from {
    width: 20px;
    height: 20px;
    top: 12px;
    left: 16px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-14283 {
  animation: animationBack-14283 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I14283_3465410041 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I14283_3465410041 {
  animation: nullClass-I14283_3465410041 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI14283_3465410041 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 18.333335876464844px;
    height: 18.33331298828125px;
    top: 1.6666666269302368px;
    left: 1.6666666269302368px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI14283_3465410041 {
  animation: animation-nullI14283_3465410041 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBg-I14283_3465410041 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHNSURBVHgBrVW7UsJAFL27yIxjY0rGRuwcGxi0N/5BfPXwBcgXYL5A/ALofeEXEHrFWGmJlS00jjPCrvfkwTAxgZDxzCy7yV7O7r17zkbQEpRNy1gnMjD+Jhq5Tne0KF4kkazRWp2HZtDm4SpS19wcJh8uJdw3Ty1Bos1DQ/OOJImuJv0RhJc0qTLPF7kNpzS1B859J5HwwDxrcncJIv5jIxocomKeVwWpJogR/+Tc2n8IESRJt7W38s9RXDrz4LIUc5TrgZQzOH527rozQkzmeVKRpDRk86Rc6xeMJzTZwYFJPEjKm9pbaWqnJQMQq0g0eIhDvPC5vB9dR6pJNVuEgXPT4Q5SOvS4IBHsXpB+pYzgGj5yZ4ILOzTCDCgjICH0G1JuSvpngHAUrLJNGcH1L6H/UmosA286XAeLMkKye7hzZ7Jh9LkZFfOkSisChoDk4G+fnDxRtsjzrbyCWNOS+W5RTUgOl8WMEFvllGvYZZ7yvTSkobuihsiFAZ/Dt/et4h6saLFHrUJxd8zv3KQ0cyQf+CgL/Gizj1vhnIgLDm8S6Et7+vRFDyUEh+ddbfy+Fl4KiYRhOvA3LInHyLTDrY+6x93esYQR8pU+Ab9ccNFMRiixywAAAABJRU5ErkJggg==);
  }
}
.bgClass-I14283_3465410041 {
  animation: animationBg-I14283_3465410041 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-I14283_3465410041 {
  from {
    width: 18.333335876464844px;
    height: 18.33331298828125px;
    top: 1.6666666269302368px;
    left: 1.6666666269302368px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I14283_3465410041 {
  animation: animationBack-I14283_3465410041 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animation-19043615 {
  from {
    width: 1440px;
    height: 80px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
    background: #e9d8a6ff;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClass-19043615 {
  animation: animation-19043615 0.001s linear 0s normal forwards;
}
@keyframes nullClass-19043615 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-19043615 {
  animation: nullClass-19043615 0.20000000298023224s linear 0s normal forwards;
}

@keyframes nullClass-1653094 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653094 {
  animation: nullClass-1653094 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653094 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 1440px;
    height: 80px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653094 {
  animation: animation-null1653094 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653094 {
  from {
    width: 1440px;
    height: 80px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653094 {
  animation: animationBack-1653094 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-1653095 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653095 {
  animation: nullClass-1653095 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653095 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 1280px;
    height: 44px;
    top: 18px;
    left: 80px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653095 {
  animation: animation-null1653095 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653095 {
  from {
    width: 1280px;
    height: 44px;
    top: 18px;
    left: 80px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653095 {
  animation: animationBack-1653095 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-1653103 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653103 {
  animation: nullClass-1653103 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653103 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 190px;
    height: 44px;
    top: 0px;
    left: 1058px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653103 {
  animation: animation-null1653103 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653103 {
  from {
    width: 190px;
    height: 44px;
    top: 0px;
    left: 1058px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653103 {
  animation: animationBack-1653103 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-I1653103_1624262067 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653103_1624262067 {
  animation: nullClass-I1653103_1624262067 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653103_1624262067 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 83px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-nullI1653103_1624262067 {
  animation: animation-nullI1653103_1624262067 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653103_1624262067 {
  from {
    width: 83px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653103_1624262067 {
  animation: animationBack-I1653103_1624262067 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653103_1624262067_6421275185 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653103_1624262067_6421275185 {
  animation: nullClass-I1653103_1624262067_6421275185 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653103_1624262067_6421275185 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 51px;
    height: 24px;
    top: 10px;
    left: 16px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653103_1624262067_6421275185 {
  animation: animation-nullI1653103_1624262067_6421275185 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653103_1624262067_6421275185 {
  from {
    width: 51px;
    height: 24px;
    top: 10px;
    left: 16px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653103_1624262067_6421275185 {
  animation: animationBack-I1653103_1624262067_6421275185 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653103_1624262067_3287433257 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653103_1624262067_3287433257 {
  animation: nullClass-I1653103_1624262067_3287433257 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653103_1624262067_3287433257 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 47px;
    height: 24px;
    top: 0px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653103_1624262067_3287433257 {
  animation: animation-nullI1653103_1624262067_3287433257 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653103_1624262067_3287433257 {
  from {
    width: 47px;
    height: 24px;
    top: 0px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653103_1624262067_3287433257 {
  animation: animationBack-I1653103_1624262067_3287433257 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653103_1624262069 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653103_1624262069 {
  animation: nullClass-I1653103_1624262069 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653103_1624262069 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 95px;
    height: 44px;
    top: 0px;
    left: 95px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #7f56d9ff;
    box-sizing: border-box;
    border-left: 1px solid #7f56d9ff;
    border-right: 1px solid #7f56d9ff;
    border-top: 1px solid #7f56d9ff;
    border-bottom: 1px solid #7f56d9ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-nullI1653103_1624262069 {
  animation: animation-nullI1653103_1624262069 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653103_1624262069 {
  from {
    width: 95px;
    height: 44px;
    top: 0px;
    left: 95px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
    background: #7f56d9ff;
    box-sizing: border-box;
    border-left: 1px solid #7f56d9ff;
    border-right: 1px solid #7f56d9ff;
    border-top: 1px solid #7f56d9ff;
    border-bottom: 1px solid #7f56d9ff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653103_1624262069 {
  animation: animationBack-I1653103_1624262069 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653103_1624262069_6421273911 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653103_1624262069_6421273911 {
  animation: nullClass-I1653103_1624262069_6421273911 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653103_1624262069_6421273911 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 63px;
    height: 24px;
    top: 10px;
    left: 16px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653103_1624262069_6421273911 {
  animation: animation-nullI1653103_1624262069_6421273911 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653103_1624262069_6421273911 {
  from {
    width: 63px;
    height: 24px;
    top: 10px;
    left: 16px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653103_1624262069_6421273911 {
  animation: animationBack-I1653103_1624262069_6421273911 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653103_1624262069_3287432949 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653103_1624262069_3287432949 {
  animation: nullClass-I1653103_1624262069_3287432949 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653103_1624262069_3287432949 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 59px;
    height: 24px;
    top: 0px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653103_1624262069_3287432949 {
  animation: animation-nullI1653103_1624262069_3287432949 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653103_1624262069_3287432949 {
  from {
    width: 59px;
    height: 24px;
    top: 0px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653103_1624262069_3287432949 {
  animation: animationBack-I1653103_1624262069_3287432949 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-1653096 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653096 {
  animation: nullClass-1653096 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653096 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 586px;
    height: 32px;
    top: 6px;
    left: 32px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653096 {
  animation: animation-null1653096 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653096 {
  from {
    width: 586px;
    height: 32px;
    top: 6px;
    left: 32px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653096 {
  animation: animationBack-1653096 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-1653097 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653097 {
  animation: nullClass-1653097 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653097 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 142px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653097 {
  animation: animation-null1653097 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653097 {
  from {
    width: 142px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653097 {
  animation: animationBack-1653097 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-I1653097_2924375962 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_2924375962 {
  animation: nullClass-I1653097_2924375962 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653097_2924375962 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 142px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653097_2924375962 {
  animation: animation-nullI1653097_2924375962 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653097_2924375962 {
  from {
    width: 142px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_2924375962 {
  animation: animationBack-I1653097_2924375962 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653097_1532267709 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_1532267709 {
  animation: nullClass-I1653097_1532267709 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653097_1532267709 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 100px;
    height: 32px;
    top: 0px;
    left: 42px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653097_1532267709 {
  animation: animation-nullI1653097_1532267709 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653097_1532267709 {
  from {
    width: 100px;
    height: 32px;
    top: 0px;
    left: 42px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_1532267709 {
  animation: animationBack-I1653097_1532267709 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653097_3563407518 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_3563407518 {
  animation: nullClass-I1653097_3563407518 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653097_3563407518 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 99.431640625px;
    height: 15.46875px;
    top: 7.75140380859375px;
    left: -0.16534423828125px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_3563407518 {
  animation: animation-nullI1653097_3563407518 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBg-I1653097_3563407518 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAAARCAYAAADEz2IYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQjSURBVHgB5VhJVtwwEC25kxc6m4gFSVhhFhnIJp0T0H2CcAPgBMAJYk4AnKCbEwROQHMCyCrDBrNKyAatAry8tFIllWy5sd0GzPT473nQVCpVqQYJ4A5BylDKl2/b8MAhSBAwNrbM5b769a2f1xGFFZmfwWBf/f6xBdcAnOMAPyHz0ak4ZoHHxDimBzVDTrxrQWMwZwqnp+tKxQqKeYnMT4mM5IuZZRBa+vxm5gDoPUKFUIdP3rh+wZy2TxAQoUsrJRGiEEr9/LqeadRCIsMAVshwbiH5i53Hp81896BuNAYtcGtvNrugQJX0Hi0joZeANx44fv05sP4R3DysELWO8ZtVitAf8L0A/4KtoXq7kCtuiPuC21BKIdCcY/xE8MBxJaWgW1nHXfwRf2N8FvHpogtqOZ+Jz2riN20A7/ouimMIKWOa++xAGlMW5fPXc2gda+DcmRZzTCeuEnPYVTr3BsxTD2PDxnBswL4hkAvBORL+B4MVuAVczVIs8yGXrECtwIHru7jY2Ese/HaAodjB5TCpDwKZ6WPnk1ABOC/56CiHfgTNJ3MSwo5TDCvkPP9B8BmuI06NQAD1IORvB+MBxYVVr80EMFbMNMaSfa6PTdk++Tg93eL22JS13uZyqZWwhUQ8ZgPpjOP8AsiatVDGmtOM0/EYJv1TvmgdC3DDqDOmdDgmEPZRMLNAbkOIlutA7Viv/HIZQd7JCsfYCiGOR41hzPO3r46+L3vz9RI3ZftE3NTmb+z3p/ZkHTeI2pSSI6xD89a6krupFTaumd/k7OAgxHvkif5Cc0azCO04Y4nDtLaRVhtuEHcq+6oNItkIbRje5dqLac3mMyyLdJxQObTKziXXgqxSCnY1m/x9xCaUBGo8vB5m1pa3/jRbrA6tx4vbRtMLjNvRvEOCYN4zaR/z3v8u3H30+TtFCYb/YNDfp8dlhJn1k2sbhk35q8ElMUEwm7eR8QixlFix1l+KyAQ88QZ3pHuwPcpeiKiceNWSkzN0Toi4fwzF1zBVGXcCkJewwHbFMbtJ/8mZJHDzPd8ePsfujGQQGIty9JMrJ/5vQ3VYOtbidowcSYYkS6IldHqDIcR6ERGrFLxo81LVEOiQB3AAjcYe1qfZCB6mKmY/JQh2PcYPUGjHI4cIse3xdsCHzEIgj1GyHq3XSAFy8s0ePGm6C09CmrZrI6yYS5RxadzVxFcEF9iEmLkRnU2P166RIckye2ZaLZOjUQqlnkjQnS/yOvfp/FHH7bA6wktIITaSClQOKmaqdNDJSQTpYsFPswtxdtYx81jXFCY3DcIoq+PfKLOAOt7GdPOsZOatAKS1APZ2I85p7vPcURkNkVfJLiJERSj4+ycuu66+LNiVSLRSVZX+ZcaYcXQ1TmgM1ChLN3M8fhrWsW7mt3VROf4H2Tv1C+hK0C4AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_3563407518 {
  animation: animationBg-I1653097_3563407518 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653097_3563407518 {
  from {
    width: 99.431640625px;
    height: 15.46875px;
    top: 7.75140380859375px;
    left: -0.16534423828125px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_3563407518 {
  animation: animationBack-I1653097_3563407518 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653097_108350705 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705 {
  animation: nullClass-I1653097_108350705 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653097_108350705 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653097_108350705 {
  animation: animation-nullI1653097_108350705 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653097_108350705 {
  from {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705 {
  animation: animationBack-I1653097_108350705 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653097_108350705_110166343 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110166343 {
  animation: nullClass-I1653097_108350705_110166343 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110166343 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(180deg, #ffffffff 0%, #d0d5ddff 100%), #ffffffff;
    box-sizing: border-box;
    border-left: 0.20000000298023224px solid #d0d5ddff;
    border-right: 0.20000000298023224px solid #d0d5ddff;
    border-top: 0.20000000298023224px solid #d0d5ddff;
    border-bottom: 0.20000000298023224px solid #d0d5ddff;
  }
}
.animationClass-nullI1653097_108350705_110166343 {
  animation: animation-nullI1653097_108350705_110166343 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110166343 {
  from {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(180deg, #ffffffff 0%, #d0d5ddff 100%), #ffffffff;
    box-sizing: border-box;
    border-left: 0.20000000298023224px solid #d0d5ddff;
    border-right: 0.20000000298023224px solid #d0d5ddff;
    border-top: 0.20000000298023224px solid #d0d5ddff;
    border-bottom: 0.20000000298023224px solid #d0d5ddff;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110166343 {
  animation: animationBack-I1653097_108350705_110166343 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_108350481 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_108350481 {
  animation: nullClass-I1653097_108350705_108350481 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_108350481 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 16px;
    height: 16px;
    top: 8px;
    left: 8px;
    opacity: 1;
    z-index: 1;
    transform: rotate(-6.03330924203505e-15deg);
    border-radius: 50%;
    background: linear-gradient(153.43deg, #53389eff 0%, #6941c6ff 100%);
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.05999999865889549),
      0px 1px 3px 0px rgba(16, 24, 40, 0.10000000149011612);
  }
}
.animationClass-nullI1653097_108350705_108350481 {
  animation: animation-nullI1653097_108350705_108350481 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_108350481 {
  from {
    width: 16px;
    height: 16px;
    top: 8px;
    left: 8px;
    opacity: 1;
    z-index: 1;
    transform: rotate(-6.03330924203505e-15deg);
    border-radius: 50%;
    background: linear-gradient(153.43deg, #53389eff 0%, #6941c6ff 100%);
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.05999999865889549),
      0px 1px 3px 0px rgba(16, 24, 40, 0.10000000149011612);
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_108350481 {
  animation: animationBack-I1653097_108350705_108350481 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110181660 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110181660 {
  animation: nullClass-I1653097_108350705_110181660 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110181660 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 16px;
    top: 16px;
    left: 0px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 8px 8px;
    background: #ffffff33;
    backdrop-filter: blur(1.25px);
  }
}
.animationClass-nullI1653097_108350705_110181660 {
  animation: animation-nullI1653097_108350705_110181660 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110181660 {
  from {
    width: 32px;
    height: 16px;
    top: 16px;
    left: 0px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 8px 8px;
    background: #ffffff33;
    backdrop-filter: blur(1.25px);
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110181660 {
  animation: animationBack-I1653097_108350705_110181660 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_108189 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_108189 {
  animation: nullClass-I1653097_108350705_108189 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_108189 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653097_108350705_108189 {
  animation: animation-nullI1653097_108350705_108189 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_108189 {
  from {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_108189 {
  animation: animationBack-I1653097_108350705_108189 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110244 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110244 {
  animation: nullClass-I1653097_108350705_110244 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110244 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 28.078125px;
    height: 28.07769775390625px;
    top: 1.9611650705337524px;
    left: 1.9611650705337524px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110244 {
  animation: animation-nullI1653097_108350705_110244 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110244 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEHSURBVHgBzVdLCoMwEJ0KxeyaI+iyqzb3P8RsBGk3WugBsiqBIDSJpiq1kGKc+BCEKLzMzJsfQCIc/vkZseHAFAfFpBClHM4K85LuAQ7+fDUQ6wKr5gpUQESOtbOGDljdo1hoL17dnpelb9kiqT62EAHiXLb6pR+/yOfEG7nXCTMFrEghFabezIASHczdncL/g8Vxik0IvJEDManBI3Ge5yegA5179wfbEIAIPpe9uOjUpaScEEvCgKcp2d9hjdWDQzHWat2lyy/KUWfendQ2IgvqxTbWMfPaDY2h+unJ40wM1KLt53HKPu+8FSDS4BWmj7s1wK0q3Kwq7eecMbPWKLPWiP23vDcdBYGhQ7FwZgAAAABJRU5ErkJggg==);
  }
}
.bgClass-I1653097_108350705_110244 {
  animation: animationBg-I1653097_108350705_110244 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110244 {
  from {
    width: 28.078125px;
    height: 28.07769775390625px;
    top: 1.9611650705337524px;
    left: 1.9611650705337524px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110244 {
  animation: animationBack-I1653097_108350705_110244 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110245 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110245 {
  animation: nullClass-I1653097_108350705_110245 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110245 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 8px;
    height: 8px;
    top: 12.000227928161621px;
    left: 12.000014305114746px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110245 {
  animation: animation-nullI1653097_108350705_110245 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110245 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABiSURBVHgBfU4rDoAwDH1bMr8jFI+g9z9EESgMO8IMqoY1WUhG6J5qXt8voEOOc4OmggSC1sLMFe9TJOMDkSuPxw8sNSIpeQKrjNC7wFdQS8jkC7p5NjIMhO2xypbK67Ib/wAwoSlMI7pPVwAAAABJRU5ErkJggg==);
  }
}
.bgClass-I1653097_108350705_110245 {
  animation: animationBg-I1653097_108350705_110245 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110245 {
  from {
    width: 8px;
    height: 8px;
    top: 12.000227928161621px;
    left: 12.000014305114746px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110245 {
  animation: animationBack-I1653097_108350705_110245 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110246 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110246 {
  animation: nullClass-I1653097_108350705_110246 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110246 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 5.5146484375px;
    height: 5.51458740234375px;
    top: 13.242415428161621px;
    left: 13.242720603942871px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110246 {
  animation: animation-nullI1653097_108350705_110246 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110246 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABXSURBVHgBbY0hEoAwDAQvGaoweUIRSER+wNP5QQQSQZ9QHwGTDor25M3tLeGLmQnSnPGQ6LYesPPaMQiDpvovzW5heC3dPHlmVe2I8FBDwxOXjqK6tOEL2sMYxrbl4rIAAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110246 {
  animation: animationBg-I1653097_108350705_110246 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110246 {
  from {
    width: 5.5146484375px;
    height: 5.51458740234375px;
    top: 13.242415428161621px;
    left: 13.242720603942871px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110246 {
  animation: animationBack-I1653097_108350705_110246 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110247 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110247 {
  animation: nullClass-I1653097_108350705_110247 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110247 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 15.961151123046875px;
    opacity: 1;
    z-index: 3;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110247 {
  animation: animation-nullI1653097_108350705_110247 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110247 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAAgCAYAAAA40qkZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAWSURBVHgB7cCBAAAAAICg/ak3mOBgRQEgAAGGtlldAAAAAElFTkSuQmCC);
  }
}
.bgClass-I1653097_108350705_110247 {
  animation: animationBg-I1653097_108350705_110247 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110247 {
  from {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 15.961151123046875px;
    opacity: 1;
    z-index: 3;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110247 {
  animation: animationBack-I1653097_108350705_110247 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110248 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110248 {
  animation: nullClass-I1653097_108350705_110248 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110248 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 0.07769775390625px;
    top: -0.00022824756422323844px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 4;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110248 {
  animation: animation-nullI1653097_108350705_110248 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110248 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACCAYAAAA5Ht7JAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgB5cCBAAAAAICg/KkHOe4CAQIAAcXYJLEAAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110248 {
  animation: animationBg-I1653097_108350705_110248 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110248 {
  from {
    width: 32px;
    height: 0.07769775390625px;
    top: -0.00022824756422323844px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 4;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110248 {
  animation: animationBack-I1653097_108350705_110248 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110249 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110249 {
  animation: nullClass-I1653097_108350705_110249 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110249 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 26.601959228515625px;
    opacity: 1;
    z-index: 5;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110249 {
  animation: animation-nullI1653097_108350705_110249 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110249 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAgCAYAAADT5RIaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBzcCBAAAAAICg/KnXON4CAKAAASyt204AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110249 {
  animation: animationBg-I1653097_108350705_110249 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110249 {
  from {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 26.601959228515625px;
    opacity: 1;
    z-index: 5;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110249 {
  animation: animationBack-I1653097_108350705_110249 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110250 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110250 {
  animation: nullClass-I1653097_108350705_110250 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110250 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 10.640777587890625px;
    opacity: 1;
    z-index: 6;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110250 {
  animation: animation-nullI1653097_108350705_110250 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110250 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAgCAYAAADT5RIaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBzcCBAAAAAICg/KnXON4CAKAAASyt204AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110250 {
  animation: animationBg-I1653097_108350705_110250 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110250 {
  from {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 10.640777587890625px;
    opacity: 1;
    z-index: 6;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110250 {
  animation: animationBack-I1653097_108350705_110250 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110251 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110251 {
  animation: nullClass-I1653097_108350705_110251 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110251 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 21.28155517578125px;
    opacity: 1;
    z-index: 7;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110251 {
  animation: animation-nullI1653097_108350705_110251 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110251 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAgCAYAAADT5RIaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBzcCBAAAAAICg/KnXON4CAKAAASyt204AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110251 {
  animation: animationBg-I1653097_108350705_110251 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110251 {
  from {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 21.28155517578125px;
    opacity: 1;
    z-index: 7;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110251 {
  animation: animationBack-I1653097_108350705_110251 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110252 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110252 {
  animation: nullClass-I1653097_108350705_110252 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110252 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 5.32037353515625px;
    opacity: 1;
    z-index: 8;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110252 {
  animation: animation-nullI1653097_108350705_110252 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110252 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAgCAYAAADT5RIaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBzcCBAAAAAICg/KnXON4CAKAAASyt204AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110252 {
  animation: animationBg-I1653097_108350705_110252 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110252 {
  from {
    width: 0.078125px;
    height: 32px;
    top: 0px;
    left: 5.32037353515625px;
    opacity: 1;
    z-index: 8;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110252 {
  animation: animationBack-I1653097_108350705_110252 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110253 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110253 {
  animation: nullClass-I1653097_108350705_110253 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110253 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 0.07769775390625px;
    top: 10.640396752435777px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 9;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110253 {
  animation: animation-nullI1653097_108350705_110253 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110253 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAABCAYAAAC/iqxnAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBxcCBAAAAAICg/KnHOGYBAIEAAdEBMP8AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110253 {
  animation: animationBg-I1653097_108350705_110253 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110253 {
  from {
    width: 32px;
    height: 0.07769775390625px;
    top: 10.640396752435777px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 9;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110253 {
  animation: animationBack-I1653097_108350705_110253 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110254 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110254 {
  animation: nullClass-I1653097_108350705_110254 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110254 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 0.07769775390625px;
    top: -5.320540747564223px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 10;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110254 {
  animation: animation-nullI1653097_108350705_110254 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110254 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAABCAYAAAC/iqxnAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBxcCBAAAAAICg/KnHOGYBAIEAAdEBMP8AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110254 {
  animation: animationBg-I1653097_108350705_110254 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110254 {
  from {
    width: 32px;
    height: 0.07769775390625px;
    top: -5.320540747564223px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 10;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110254 {
  animation: animationBack-I1653097_108350705_110254 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110255 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110255 {
  animation: nullClass-I1653097_108350705_110255 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110255 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 0.07769775390625px;
    top: 5.320084252435777px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 11;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110255 {
  animation: animation-nullI1653097_108350705_110255 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110255 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAABCAYAAAC/iqxnAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBxcCBAAAAAICg/KnHOGYBAIEAAdEBMP8AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110255 {
  animation: animationBg-I1653097_108350705_110255 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110255 {
  from {
    width: 32px;
    height: 0.07769775390625px;
    top: 5.320084252435777px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 11;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110255 {
  animation: animationBack-I1653097_108350705_110255 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-I1653097_108350705_110256 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653097_108350705_110256 {
  animation: nullClass-I1653097_108350705_110256 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653097_108350705_110256 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 32px;
    height: 0.07769775390625px;
    top: -10.640853247564223px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 12;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653097_108350705_110256 {
  animation: animation-nullI1653097_108350705_110256 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I1653097_108350705_110256 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAABCAYAAAC/iqxnAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBxcCBAAAAAICg/KnHOGYBAIEAAdEBMP8AAAAASUVORK5CYII=);
  }
}
.bgClass-I1653097_108350705_110256 {
  animation: animationBg-I1653097_108350705_110256 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I1653097_108350705_110256 {
  from {
    width: 32px;
    height: 0.07769775390625px;
    top: -10.640853247564223px;
    left: 15.961165046484481px;
    opacity: 1;
    z-index: 12;
    transform: rotate(90.00000250447808deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653097_108350705_110256 {
  animation: animationBack-I1653097_108350705_110256 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-1653098 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653098 {
  animation: nullClass-1653098 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653098 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 404px;
    height: 24px;
    top: 4px;
    left: 182px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653098 {
  animation: animation-null1653098 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653098 {
  from {
    width: 404px;
    height: 24px;
    top: 4px;
    left: 182px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653098 {
  animation: animationBack-1653098 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-1653099 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653099 {
  animation: nullClass-1653099 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653099 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 46px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653099 {
  animation: animation-null1653099 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653099 {
  from {
    width: 46px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653099 {
  animation: animationBack-1653099 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-I1653099_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653099_3287433397 {
  animation: nullClass-I1653099_3287433397 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653099_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 46px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653099_3287433397 {
  animation: animation-nullI1653099_3287433397 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653099_3287433397 {
  from {
    width: 46px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653099_3287433397 {
  animation: animationBack-I1653099_3287433397 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-1653102 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653102 {
  animation: nullClass-1653102 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653102 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 54px;
    height: 24px;
    top: 0px;
    left: 350px;
    opacity: 1;
    z-index: 3;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653102 {
  animation: animation-null1653102 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653102 {
  from {
    width: 54px;
    height: 24px;
    top: 0px;
    left: 350px;
    opacity: 1;
    z-index: 3;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653102 {
  animation: animationBack-1653102 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-I1653102_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653102_3287433397 {
  animation: nullClass-I1653102_3287433397 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653102_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 54px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653102_3287433397 {
  animation: animation-nullI1653102_3287433397 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653102_3287433397 {
  from {
    width: 54px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653102_3287433397 {
  animation: animationBack-I1653102_3287433397 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-1653100 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653100 {
  animation: nullClass-1653100 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653100 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 98px;
    height: 24px;
    top: 0px;
    left: 78px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653100 {
  animation: animation-null1653100 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653100 {
  from {
    width: 98px;
    height: 24px;
    top: 0px;
    left: 78px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653100 {
  animation: animationBack-1653100 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-I1653100_1288478 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653100_1288478 {
  animation: nullClass-I1653100_1288478 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653100_1288478 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 98px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653100_1288478 {
  animation: animation-nullI1653100_1288478 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653100_1288478 {
  from {
    width: 98px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653100_1288478 {
  animation: animationBack-I1653100_1288478 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653100_1288478_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653100_1288478_3287433397 {
  animation: nullClass-I1653100_1288478_3287433397 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653100_1288478_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 70px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653100_1288478_3287433397 {
  animation: animation-nullI1653100_1288478_3287433397 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653100_1288478_3287433397 {
  from {
    width: 70px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653100_1288478_3287433397 {
  animation: animationBack-I1653100_1288478_3287433397 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653100_1288478_3468468154 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653100_1288478_3468468154 {
  animation: nullClass-I1653100_1288478_3468468154 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653100_1288478_3468468154 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 78px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653100_1288478_3468468154 {
  animation: animation-nullI1653100_1288478_3468468154 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653100_1288478_3468468154 {
  from {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 78px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653100_1288478_3468468154 {
  animation: animationBack-I1653100_1288478_3468468154 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653100_1288478_3468468154_3463404534 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653100_1288478_3468468154_3463404534 {
  animation: nullClass-I1653100_1288478_3468468154_3463404534 0.001s linear 0s
    normal forwards;
}
@keyframes animation-nullI1653100_1288478_3468468154_3463404534 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 11.666015625px;
    height: 6.6666259765625px;
    top: 7.5px;
    left: 5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI1653100_1288478_3468468154_3463404534 {
  animation: animation-nullI1653100_1288478_3468468154_3463404534
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBg-I1653100_1288478_3468468154_3463404534 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACJSURBVHgBhZC7DYNQDEV9DYNkhKyQIlLKNBmAIlJGikTBABRQIlEwAozACPRI74KRnoQeP1fW0bm2ZTw/33s0RkNV/ns5Ke8pqBljtgbOZBANY1co4RIKRanNXsjL1jswwRpCMEP3qPO0C2WCC0c4yYeMhfLC9tZbyM4M5U3g6Ay5qtf7dzv62gQSdFFVZTKmwAAAAABJRU5ErkJggg==);
  }
}
.bgClass-I1653100_1288478_3468468154_3463404534 {
  animation: animationBg-I1653100_1288478_3468468154_3463404534
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-I1653100_1288478_3468468154_3463404534 {
  from {
    width: 11.666015625px;
    height: 6.6666259765625px;
    top: 7.5px;
    left: 5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653100_1288478_3468468154_3463404534 {
  animation: animationBack-I1653100_1288478_3468468154_3463404534
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-1653101 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-1653101 {
  animation: nullClass-1653101 0.001s linear 0s normal forwards;
}
@keyframes animation-null1653101 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 208px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null1653101 {
  animation: animation-null1653101 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-1653101 {
  from {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 208px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-1653101 {
  animation: animationBack-1653101 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-I1653101_1288474 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_1288474 {
  animation: nullClass-I1653101_1288474 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653101_1288474 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_1288474 {
  animation: animation-nullI1653101_1288474 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653101_1288474 {
  from {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_1288474 {
  animation: animationBack-I1653101_1288474 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653101_1288474_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_1288474_3287433397 {
  animation: nullClass-I1653101_1288474_3287433397 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_1288474_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 82px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_1288474_3287433397 {
  animation: animation-nullI1653101_1288474_3287433397 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_1288474_3287433397 {
  from {
    width: 82px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_1288474_3287433397 {
  animation: animationBack-I1653101_1288474_3287433397 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_1288474_3468468154 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_1288474_3468468154 {
  animation: nullClass-I1653101_1288474_3468468154 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_1288474_3468468154 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 90px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_1288474_3468468154 {
  animation: animation-nullI1653101_1288474_3468468154 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_1288474_3468468154 {
  from {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 90px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_1288474_3468468154 {
  animation: animationBack-I1653101_1288474_3468468154 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_1288474_3468468154_3463404558 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_1288474_3468468154_3463404558 {
  animation: nullClass-I1653101_1288474_3468468154_3463404558 0.001s linear 0s
    normal forwards;
}
@keyframes animation-nullI1653101_1288474_3468468154_3463404558 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 11.666015625px;
    height: 6.6666259765625px;
    top: 7.5px;
    left: 5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI1653101_1288474_3468468154_3463404558 {
  animation: animation-nullI1653101_1288474_3468468154_3463404558
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBg-I1653101_1288474_3468468154_3463404558 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBdY2xDYQwEAS99x98JcRPQk5AAQQUQEBVBBRACQQ0gAQhAUVQAPKCkSwsY19k38zoPiowRdX8kyT7beu0+0xCMoiRX87m7XOE5AdCaeh86NvlFbgywfyG19+PEJIttHs3Qkz2L9tIQOlishmzM4yKSohO5EAZk91IDkk1WJ+5SFl5rTC8FwAAAABJRU5ErkJggg==);
  }
}
.bgClass-I1653101_1288474_3468468154_3463404558 {
  animation: animationBg-I1653101_1288474_3468468154_3463404558
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-I1653101_1288474_3468468154_3463404558 {
  from {
    width: 11.666015625px;
    height: 6.6666259765625px;
    top: 7.5px;
    left: 5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_1288474_3468468154_3463404558 {
  animation: animationBack-I1653101_1288474_3468468154_3463404558
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447029 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447029 {
  animation: nullClass-I1653101_4942447029 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653101_4942447029 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 640px;
    height: 332px;
    top: 40px;
    left: -265px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 12px 12px 12px 12px;
    background: #ffffffff;
    box-sizing: border-box;
    border-left: 1px solid #eaecf0ff;
    border-right: 1px solid #eaecf0ff;
    border-top: 1px solid #eaecf0ff;
    border-bottom: 1px solid #eaecf0ff;
    box-shadow:
      0px 4px 6px -2px rgba(16, 24, 40, 0.029999999329447746),
      0px 12px 16px -4px rgba(16, 24, 40, 0.07999999821186066);
  }
}
.animationClass-nullI1653101_4942447029 {
  animation: animation-nullI1653101_4942447029 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653101_4942447029 {
  from {
    width: 640px;
    height: 332px;
    top: 40px;
    left: -265px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 12px 12px 12px 12px;
    background: #ffffffff;
    box-sizing: border-box;
    border-left: 1px solid #eaecf0ff;
    border-right: 1px solid #eaecf0ff;
    border-top: 1px solid #eaecf0ff;
    border-bottom: 1px solid #eaecf0ff;
    box-shadow:
      0px 4px 6px -2px rgba(16, 24, 40, 0.029999999329447746),
      0px 12px 16px -4px rgba(16, 24, 40, 0.07999999821186066);
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447029 {
  animation: animationBack-I1653101_4942447029 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653101_4942447030 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447030 {
  animation: nullClass-I1653101_4942447030 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653101_4942447030 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 336px;
    height: 332px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447030 {
  animation: animation-nullI1653101_4942447030 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653101_4942447030 {
  from {
    width: 336px;
    height: 332px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447030 {
  animation: animationBack-I1653101_4942447030 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653101_4942447031 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447031 {
  animation: nullClass-I1653101_4942447031 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653101_4942447031 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 296px;
    height: 92px;
    top: 24px;
    left: 20px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-nullI1653101_4942447031 {
  animation: animation-nullI1653101_4942447031 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653101_4942447031 {
  from {
    width: 296px;
    height: 92px;
    top: 24px;
    left: 20px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447031 {
  animation: animationBack-I1653101_4942447031 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653101_4942447031_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447031_128214433 {
  animation: nullClass-I1653101_4942447031_128214433 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447031_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447031_128214433 {
  animation: animation-nullI1653101_4942447031_128214433 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447031_128214433 {
  from {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447031_128214433 {
  animation: animationBack-I1653101_4942447031_128214433 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447031_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447031_128214434 {
  animation: nullClass-I1653101_4942447031_128214434 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447031_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447031_128214434 {
  animation: animation-nullI1653101_4942447031_128214434 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447031_128214434 {
  from {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447031_128214434 {
  animation: animationBack-I1653101_4942447031_128214434 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447031_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447031_128214438 {
  animation: nullClass-I1653101_4942447031_128214438 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447031_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447031_128214438 {
  animation: animation-nullI1653101_4942447031_128214438 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447031_128214438 {
  from {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447031_128214438 {
  animation: animationBack-I1653101_4942447031_128214438 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447031_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447031_128214435 {
  animation: nullClass-I1653101_4942447031_128214435 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447031_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447031_128214435 {
  animation: animation-nullI1653101_4942447031_128214435 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447031_128214435 {
  from {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447031_128214435 {
  animation: animationBack-I1653101_4942447031_128214435 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447031_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447031_128214436 {
  animation: nullClass-I1653101_4942447031_128214436 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447031_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 35px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447031_128214436 {
  animation: animation-nullI1653101_4942447031_128214436 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447031_128214436 {
  from {
    width: 35px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447031_128214436 {
  animation: animationBack-I1653101_4942447031_128214436 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447031_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447031_3474462889 {
  animation: nullClass-I1653101_4942447031_3474462889 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447031_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447031_3474462889 {
  animation: animation-nullI1653101_4942447031_3474462889 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447031_3474462889 {
  from {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447031_3474462889 {
  animation: animationBack-I1653101_4942447031_3474462889 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447031_3474462889_3463404148 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447031_3474462889_3463404148 {
  animation: nullClass-I1653101_4942447031_3474462889_3463404148 0.001s linear
    0s normal forwards;
}
@keyframes animation-nullI1653101_4942447031_3474462889_3463404148 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 18px;
    height: 22px;
    top: 2px;
    left: 4px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI1653101_4942447031_3474462889_3463404148 {
  animation: animation-nullI1653101_4942447031_3474462889_3463404148
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBg-I1653101_4942447031_3474462889_3463404148 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAWCAYAAADNX8xBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADmSURBVHgB7ZQ9DoJAEEZnVq1sPAIVkQ5uwBFsMN5CPAGLFyCeQO2MNNzAI2BHpOIIlIRExl1EorHhr+Q1O7PJvmQ2mQ9BwDeRQs/JEZB00S6gOSkAntyrusNSUrCwpeAHgsKdCoknJaI5szyzeWCkTQXcinVCChHYlonelJcsB95GUop89V6VCykqR+KBlkAPGAzEKBpFQzD9FI4V3zps/79ISEzoQS3qsv0SZ/0gedZ/1GX7v5Gi6nHWWfIWEZaZQrO5x1eR0uaxDLaqTIeJWqID4xctQVYYog+gHrMxKYq83vtL+wUJHFb9WmexMQAAAABJRU5ErkJggg==);
  }
}
.bgClass-I1653101_4942447031_3474462889_3463404148 {
  animation: animationBg-I1653101_4942447031_3474462889_3463404148
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447031_3474462889_3463404148 {
  from {
    width: 18px;
    height: 22px;
    top: 2px;
    left: 4px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447031_3474462889_3463404148 {
  animation: animationBack-I1653101_4942447031_3474462889_3463404148
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447032 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447032 {
  animation: nullClass-I1653101_4942447032 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653101_4942447032 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 296px;
    height: 92px;
    top: 120px;
    left: 20px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-nullI1653101_4942447032 {
  animation: animation-nullI1653101_4942447032 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653101_4942447032 {
  from {
    width: 296px;
    height: 92px;
    top: 120px;
    left: 20px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447032 {
  animation: animationBack-I1653101_4942447032 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653101_4942447032_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447032_128214433 {
  animation: nullClass-I1653101_4942447032_128214433 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447032_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447032_128214433 {
  animation: animation-nullI1653101_4942447032_128214433 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447032_128214433 {
  from {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447032_128214433 {
  animation: animationBack-I1653101_4942447032_128214433 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447032_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447032_128214434 {
  animation: nullClass-I1653101_4942447032_128214434 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447032_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447032_128214434 {
  animation: animation-nullI1653101_4942447032_128214434 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447032_128214434 {
  from {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447032_128214434 {
  animation: animationBack-I1653101_4942447032_128214434 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447032_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447032_128214438 {
  animation: nullClass-I1653101_4942447032_128214438 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447032_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447032_128214438 {
  animation: animation-nullI1653101_4942447032_128214438 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447032_128214438 {
  from {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447032_128214438 {
  animation: animationBack-I1653101_4942447032_128214438 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447032_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447032_128214435 {
  animation: nullClass-I1653101_4942447032_128214435 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447032_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447032_128214435 {
  animation: animation-nullI1653101_4942447032_128214435 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447032_128214435 {
  from {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447032_128214435 {
  animation: animationBack-I1653101_4942447032_128214435 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447032_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447032_128214436 {
  animation: nullClass-I1653101_4942447032_128214436 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447032_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 134px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447032_128214436 {
  animation: animation-nullI1653101_4942447032_128214436 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447032_128214436 {
  from {
    width: 134px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447032_128214436 {
  animation: animationBack-I1653101_4942447032_128214436 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447032_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447032_3474462889 {
  animation: nullClass-I1653101_4942447032_3474462889 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447032_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447032_3474462889 {
  animation: animation-nullI1653101_4942447032_3474462889 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447032_3474462889 {
  from {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447032_3474462889 {
  animation: animationBack-I1653101_4942447032_3474462889 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447032_3474462889_3463407150 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447032_3474462889_3463407150 {
  animation: nullClass-I1653101_4942447032_3474462889_3463407150 0.001s linear
    0s normal forwards;
}
@keyframes animation-nullI1653101_4942447032_3474462889_3463407150 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 22px;
    height: 22px;
    top: 2px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI1653101_4942447032_3474462889_3463407150 {
  animation: animation-nullI1653101_4942447032_3474462889_3463407150
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBg-I1653101_4942447032_3474462889_3463407150 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJSSURBVHgBpVU7chNBEO2e3cJFpiNIESjDJ2A5AaIK22QWJxDOgZ3F5FgnYBViCMwJvJwAZwIn1hEUmbLNdNO9vxqpdlei3FWqGY1mXv9ePyGU9m5vPjZoPhPT6+OvwxTuaabaIELfX5ss3v99pR/YwlAjVTDk4CkgR8CYMbofzLDwI7cv5xEbc657R/Ts47dh1gUcavqFCy5dcYRgInGmllYXOQgOgYs7gTETWbIuYINSUwZKNNICQSKW73peR/tq3hfQsfcusqOfvS7g0Jbpvt+fW41Uy/DhdGj9S85BZLBwWmXldh6OZXfSBlw3z0hN/XU1rTx1QHAzJJ7mZ2SeQ4chbLDk4HJCzBrZIjl9NLCjqx4/uFVm9Aj56PjL45OtgbV+mmoelTIlv8lHSQkSH/x6I2X5lJ8zXBDQNLiDzJ4NF43Ab4VSZccVrGrOUkCTZC2yElzv9utDxtSxmykVa+CS+P6lzEk9Q3ctkewuocWszAGDULHKrLCFgQ5DQ71OUCnZXzKNv6+UQqeLDIyFdoe+d2nSdL1JZVMt+CWToUGiqfVLseJAB0K4ywXNnuiZzwCPKQW3DX3Hmz+pn91GunkMWOLt9UAf1/3wmNJaii7ZjPcuz/NpA/cCGXulvlwIr3fbAgprD12yKalK1FHA4aQ4YPFDU+iwcE02QVfVDV828WYnlWmLVyh1t0HdNC1hQexNmMpmXMupmD0b5B2vXzGk/pQ1RqzySA1Cvy5GSiMR+pHuHdMMtjVNX7rNurbd+Z+/prp5GiFjs2xWpuoGW9o/v7lHkl7rMOgAAAAASUVORK5CYII=);
  }
}
.bgClass-I1653101_4942447032_3474462889_3463407150 {
  animation: animationBg-I1653101_4942447032_3474462889_3463407150
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447032_3474462889_3463407150 {
  from {
    width: 22px;
    height: 22px;
    top: 2px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447032_3474462889_3463407150 {
  animation: animationBack-I1653101_4942447032_3474462889_3463407150
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447033 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447033 {
  animation: nullClass-I1653101_4942447033 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653101_4942447033 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 296px;
    height: 92px;
    top: 216px;
    left: 20px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-nullI1653101_4942447033 {
  animation: animation-nullI1653101_4942447033 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653101_4942447033 {
  from {
    width: 296px;
    height: 92px;
    top: 216px;
    left: 20px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447033 {
  animation: animationBack-I1653101_4942447033 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653101_4942447033_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447033_128214433 {
  animation: nullClass-I1653101_4942447033_128214433 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447033_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447033_128214433 {
  animation: animation-nullI1653101_4942447033_128214433 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447033_128214433 {
  from {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447033_128214433 {
  animation: animationBack-I1653101_4942447033_128214433 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447033_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447033_128214434 {
  animation: nullClass-I1653101_4942447033_128214434 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447033_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447033_128214434 {
  animation: animation-nullI1653101_4942447033_128214434 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447033_128214434 {
  from {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447033_128214434 {
  animation: animationBack-I1653101_4942447033_128214434 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447033_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447033_128214438 {
  animation: nullClass-I1653101_4942447033_128214438 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447033_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447033_128214438 {
  animation: animation-nullI1653101_4942447033_128214438 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447033_128214438 {
  from {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447033_128214438 {
  animation: animationBack-I1653101_4942447033_128214438 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447033_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447033_128214435 {
  animation: nullClass-I1653101_4942447033_128214435 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447033_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447033_128214435 {
  animation: animation-nullI1653101_4942447033_128214435 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447033_128214435 {
  from {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447033_128214435 {
  animation: animationBack-I1653101_4942447033_128214435 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447033_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447033_128214436 {
  animation: nullClass-I1653101_4942447033_128214436 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447033_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 114px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447033_128214436 {
  animation: animation-nullI1653101_4942447033_128214436 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447033_128214436 {
  from {
    width: 114px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447033_128214436 {
  animation: animationBack-I1653101_4942447033_128214436 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447033_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447033_3474462889 {
  animation: nullClass-I1653101_4942447033_3474462889 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447033_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447033_3474462889 {
  animation: animation-nullI1653101_4942447033_3474462889 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447033_3474462889 {
  from {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447033_3474462889 {
  animation: animationBack-I1653101_4942447033_3474462889 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447033_3474462889_3463405927 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447033_3474462889_3463405927 {
  animation: nullClass-I1653101_4942447033_3474462889_3463405927 0.001s linear
    0s normal forwards;
}
@keyframes animation-nullI1653101_4942447033_3474462889_3463405927 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 22px;
    height: 22px;
    top: 2px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI1653101_4942447033_3474462889_3463405927 {
  animation: animation-nullI1653101_4942447033_3474462889_3463405927
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBg-I1653101_4942447033_3474462889_3463405927 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKlSURBVHgBlVVLbhNBEK3uIQ5ELHIDxgtAIyERnyD2CTCIWOwwJ0jYI9wTEFvgBDg7ZLNwTmDnBPbOwkh4juCVwQ7TTVX1eDIeu8dKSZGmU1Wv6/P6WYDDVH14GO/fa0ojjvF4hH9+4orAiEhDfOFdw0D1gmhbvtgGqEv3TwXoMzwewg4zINV552FYCKxejX2jZT+tzohBvjJ1MsHq4yMj5DM81lddiKWuZasXDtBIaP1G/QgGUGAbORlwmba0CjAwEst5ZRcoA38PEKxUwc8R5ZqS7NMoU+Cw8auV3no9x1srszQ5CXSC98ozBK9RLmHo0t0zHkXSzpQPS13OzinxDfFzpo0OP3SDtvOCl+Oqkdw1XjQvyziGKo/CmMs8dbSGJlhm+FLIb62TCbY69rcC0+hw2RTPNJXgvbYzMT1XNUlCBMJUcY7T93Z0G0YM4s41VCVI49t/xyM3bnwlpK4Z0DYRtGo1fk7z1XseDNgvxFMs1HJWdZ84gdmPDDjvBE2kYboorH6YXS7FJJ++hFvav/XjLMugrEnsK6KPjy9+P4CiirGyVmPy2ZMZvhvxfC2GXyVbdIcCKHDhLajF9jZQYbxjUzo4xWlT2zOc8VfVDVQ+LsanLulpoEhhxebKlm7ZAduRq0C0Q3Yg1yuqswnKGFY/cAjxhRSL/TZVQcnvkORrgcYjCkaJ/23YfVRzySQ9JliJEooWi5BqjBXKH3EzYp3ILYTm61rSyo+johfqIyVDZI+ywPUpOpbs4KXk9KLIGHTvoI/90+KisPO4zN2yk4REJvzEALrd9XSzRqPjShNQks2Vr1joAXooPpe47dGn5AHRhfEePVlctl0qQJHQr11wM/NdxtSD5d8v+dEJVwZvGZXP2Mp8yP6YkrAjTcXiT9u1i/9B5mWfiJM7jAAAAABJRU5ErkJggg==);
  }
}
.bgClass-I1653101_4942447033_3474462889_3463405927 {
  animation: animationBg-I1653101_4942447033_3474462889_3463405927
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447033_3474462889_3463405927 {
  from {
    width: 22px;
    height: 22px;
    top: 2px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447033_3474462889_3463405927 {
  animation: animationBack-I1653101_4942447033_3474462889_3463405927
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447034 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447034 {
  animation: nullClass-I1653101_4942447034 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653101_4942447034 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 304px;
    height: 332px;
    top: 0px;
    left: 336px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
    background: #f9fafbff;
  }
}
.animationClass-nullI1653101_4942447034 {
  animation: animation-nullI1653101_4942447034 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653101_4942447034 {
  from {
    width: 304px;
    height: 332px;
    top: 0px;
    left: 336px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
    background: #f9fafbff;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447034 {
  animation: animationBack-I1653101_4942447034 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653101_4942447035 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035 {
  animation: nullClass-I1653101_4942447035 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI1653101_4942447035 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 264px;
    height: 284px;
    top: 20px;
    left: 20px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-nullI1653101_4942447035 {
  animation: animation-nullI1653101_4942447035 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I1653101_4942447035 {
  from {
    width: 264px;
    height: 284px;
    top: 20px;
    left: 20px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035 {
  animation: animationBack-I1653101_4942447035 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219897 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219897 {
  animation: nullClass-I1653101_4942447035_128219897 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219897 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 100px;
    top: 172px;
    left: 12px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219897 {
  animation: animation-nullI1653101_4942447035_128219897 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219897 {
  from {
    width: 240px;
    height: 100px;
    top: 172px;
    left: 12px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219897 {
  animation: animationBack-I1653101_4942447035_128219897 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219898 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219898 {
  animation: nullClass-I1653101_4942447035_128219898 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219898 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219898 {
  animation: animation-nullI1653101_4942447035_128219898 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219898 {
  from {
    width: 240px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219898 {
  animation: animationBack-I1653101_4942447035_128219898 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219899 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219899 {
  animation: nullClass-I1653101_4942447035_128219899 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219899 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219899 {
  animation: animation-nullI1653101_4942447035_128219899 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219899 {
  from {
    width: 240px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219899 {
  animation: animationBack-I1653101_4942447035_128219899 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219900 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219900 {
  animation: nullClass-I1653101_4942447035_128219900 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219900 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219900 {
  animation: animation-nullI1653101_4942447035_128219900 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219900 {
  from {
    width: 240px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219900 {
  animation: animationBack-I1653101_4942447035_128219900 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219901 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219901 {
  animation: nullClass-I1653101_4942447035_128219901 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219901 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 140px;
    height: 20px;
    top: 80px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219901 {
  animation: animation-nullI1653101_4942447035_128219901 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219901 {
  from {
    width: 140px;
    height: 20px;
    top: 80px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219901 {
  animation: animationBack-I1653101_4942447035_128219901 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219902 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219902 {
  animation: nullClass-I1653101_4942447035_128219902 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219902 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 54px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219902 {
  animation: animation-nullI1653101_4942447035_128219902 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219902 {
  from {
    width: 54px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219902 {
  animation: animationBack-I1653101_4942447035_128219902 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219902_3287433377 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219902_3287433377 {
  animation: nullClass-I1653101_4942447035_128219902_3287433377 0.001s linear 0s
    normal forwards;
}
@keyframes animation-nullI1653101_4942447035_128219902_3287433377 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 54px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219902_3287433377 {
  animation: animation-nullI1653101_4942447035_128219902_3287433377
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219902_3287433377 {
  from {
    width: 54px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219902_3287433377 {
  animation: animationBack-I1653101_4942447035_128219902_3287433377
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219903 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219903 {
  animation: nullClass-I1653101_4942447035_128219903 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219903 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 74px;
    height: 20px;
    top: 0px;
    left: 66px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219903 {
  animation: animation-nullI1653101_4942447035_128219903 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219903 {
  from {
    width: 74px;
    height: 20px;
    top: 0px;
    left: 66px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219903 {
  animation: animationBack-I1653101_4942447035_128219903 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219903_3287433289 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219903_3287433289 {
  animation: nullClass-I1653101_4942447035_128219903_3287433289 0.001s linear 0s
    normal forwards;
}
@keyframes animation-nullI1653101_4942447035_128219903_3287433289 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 74px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219903_3287433289 {
  animation: animation-nullI1653101_4942447035_128219903_3287433289
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219903_3287433289 {
  from {
    width: 74px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219903_3287433289 {
  animation: animationBack-I1653101_4942447035_128219903_3287433289
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219895 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219895 {
  animation: nullClass-I1653101_4942447035_128219895 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219895 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 136px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 6px 6px 6px 6px;
  }
}
.animationClass-nullI1653101_4942447035_128219895 {
  animation: animation-nullI1653101_4942447035_128219895 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219895 {
  from {
    width: 240px;
    height: 136px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 6px 6px 6px 6px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219895 {
  animation: animationBack-I1653101_4942447035_128219895 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_128219896 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_128219896 {
  animation: nullClass-I1653101_4942447035_128219896 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_128219896 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 136px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI1653101_4942447035_128219896 {
  animation: animation-nullI1653101_4942447035_128219896 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBg-I1653101_4942447035_128219896 {
  from {
    opacity: 1;
  }
  to {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./images/image9.png");
    opacity: 1;
  }
}
.bgClass-I1653101_4942447035_128219896 {
  animation: animationBg-I1653101_4942447035_128219896 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_128219896 {
  from {
    width: 240px;
    height: 136px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_128219896 {
  animation: animationBack-I1653101_4942447035_128219896 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_2647402504 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_2647402504 {
  animation: nullClass-I1653101_4942447035_2647402504 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI1653101_4942447035_2647402504 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 48px;
    height: 48px;
    top: 80px;
    left: 8px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 9999px 9999px 9999px 9999px;
    backdrop-filter: blur(4px);
  }
}
.animationClass-nullI1653101_4942447035_2647402504 {
  animation: animation-nullI1653101_4942447035_2647402504 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_2647402504 {
  from {
    width: 48px;
    height: 48px;
    top: 80px;
    left: 8px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 9999px 9999px 9999px 9999px;
    backdrop-filter: blur(4px);
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_2647402504 {
  animation: animationBack-I1653101_4942447035_2647402504 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I1653101_4942447035_2647402504_137712743 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I1653101_4942447035_2647402504_137712743 {
  animation: nullClass-I1653101_4942447035_2647402504_137712743 0.001s linear 0s
    normal forwards;
}
@keyframes animation-nullI1653101_4942447035_2647402504_137712743 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 48px;
    height: 48px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI1653101_4942447035_2647402504_137712743 {
  animation: animation-nullI1653101_4942447035_2647402504_137712743
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBg-I1653101_4942447035_2647402504_137712743 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/figma-plugin-a7287.appspot.com/o/user-images%2F26-jan-2024%2Fabiywasihun1706244140275%2Fimage-I165-3101_4942-447035_2647-402504_1377-12743.png?alt=media&token=0);
  }
}
.bgClass-I1653101_4942447035_2647402504_137712743 {
  animation: animationBg-I1653101_4942447035_2647402504_137712743
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-I1653101_4942447035_2647402504_137712743 {
  from {
    width: 48px;
    height: 48px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I1653101_4942447035_2647402504_137712743 {
  animation: animationBack-I1653101_4942447035_2647402504_137712743
    0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-14284 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-14284 {
  animation: nullClass-14284 0.001s linear 0s normal forwards;
}
@keyframes animation-null14284 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 93px;
    height: 24px;
    top: 10px;
    left: 42px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null14284 {
  animation: animation-null14284 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14284 {
  from {
    width: 93px;
    height: 24px;
    top: 10px;
    left: 42px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-14284 {
  animation: animationBack-14284 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-14285 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-14285 {
  animation: nullClass-14285 0.001s linear 0s normal forwards;
}
@keyframes animation-null14285 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 89px;
    height: 24px;
    top: 0px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-null14285 {
  animation: animation-null14285 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14285 {
  from {
    width: 89px;
    height: 24px;
    top: 0px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-14285 {
  animation: animationBack-14285 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-16785 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-16785 {
  animation: nullClass-16785 0.001s linear 0s normal forwards;
}
@keyframes animation-null16785 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 640px;
    height: 332px;
    top: 40px;
    left: -265px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 12px 12px 12px 12px;
    background: #ffffffff;
    box-sizing: border-box;
    border-left: 1px solid #eaecf0ff;
    border-right: 1px solid #eaecf0ff;
    border-top: 1px solid #eaecf0ff;
    border-bottom: 1px solid #eaecf0ff;
    box-shadow:
      0px 4px 6px -2px rgba(16, 24, 40, 0.029999999329447746),
      0px 12px 16px -4px rgba(16, 24, 40, 0.07999999821186066);
  }
}
.animationClass-null16785 {
  animation: animation-null16785 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-16785 {
  from {
    width: 640px;
    height: 332px;
    top: 40px;
    left: -265px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 12px 12px 12px 12px;
    background: #ffffffff;
    box-sizing: border-box;
    border-left: 1px solid #eaecf0ff;
    border-right: 1px solid #eaecf0ff;
    border-top: 1px solid #eaecf0ff;
    border-bottom: 1px solid #eaecf0ff;
    box-shadow:
      0px 4px 6px -2px rgba(16, 24, 40, 0.029999999329447746),
      0px 12px 16px -4px rgba(16, 24, 40, 0.07999999821186066);
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-16785 {
  animation: animationBack-16785 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-16786 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-16786 {
  animation: nullClass-16786 0.001s linear 0s normal forwards;
}
@keyframes animation-null16786 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 336px;
    height: 332px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null16786 {
  animation: animation-null16786 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-16786 {
  from {
    width: 336px;
    height: 332px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-16786 {
  animation: animationBack-16786 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-16787 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-16787 {
  animation: nullClass-16787 0.001s linear 0s normal forwards;
}
@keyframes animation-null16787 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 296px;
    height: 92px;
    top: 24px;
    left: 20px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-null16787 {
  animation: animation-null16787 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-16787 {
  from {
    width: 296px;
    height: 92px;
    top: 24px;
    left: 20px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-16787 {
  animation: animationBack-16787 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I16787_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16787_128214433 {
  animation: nullClass-I16787_128214433 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16787_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16787_128214433 {
  animation: animation-nullI16787_128214433 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16787_128214433 {
  from {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16787_128214433 {
  animation: animationBack-I16787_128214433 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16787_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16787_128214434 {
  animation: nullClass-I16787_128214434 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16787_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16787_128214434 {
  animation: animation-nullI16787_128214434 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16787_128214434 {
  from {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16787_128214434 {
  animation: animationBack-I16787_128214434 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16787_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16787_128214438 {
  animation: nullClass-I16787_128214438 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16787_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16787_128214438 {
  animation: animation-nullI16787_128214438 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16787_128214438 {
  from {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16787_128214438 {
  animation: animationBack-I16787_128214438 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16787_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16787_128214435 {
  animation: nullClass-I16787_128214435 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16787_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16787_128214435 {
  animation: animation-nullI16787_128214435 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16787_128214435 {
  from {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16787_128214435 {
  animation: animationBack-I16787_128214435 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16787_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16787_128214436 {
  animation: nullClass-I16787_128214436 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16787_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 35px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16787_128214436 {
  animation: animation-nullI16787_128214436 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16787_128214436 {
  from {
    width: 35px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16787_128214436 {
  animation: animationBack-I16787_128214436 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16787_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16787_3474462889 {
  animation: nullClass-I16787_3474462889 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16787_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16787_3474462889 {
  animation: animation-nullI16787_3474462889 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16787_3474462889 {
  from {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16787_3474462889 {
  animation: animationBack-I16787_3474462889 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16787_3474462889_3463404148 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16787_3474462889_3463404148 {
  animation: nullClass-I16787_3474462889_3463404148 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI16787_3474462889_3463404148 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 16px;
    height: 20px;
    top: 2px;
    left: 4px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI16787_3474462889_3463404148 {
  animation: animation-nullI16787_3474462889_3463404148 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBg-I16787_3474462889_3463404148 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC);
  }
}
.bgClass-I16787_3474462889_3463404148 {
  animation: animationBg-I16787_3474462889_3463404148 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I16787_3474462889_3463404148 {
  from {
    width: 16px;
    height: 20px;
    top: 2px;
    left: 4px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16787_3474462889_3463404148 {
  animation: animationBack-I16787_3474462889_3463404148 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-16788 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-16788 {
  animation: nullClass-16788 0.001s linear 0s normal forwards;
}
@keyframes animation-null16788 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 296px;
    height: 92px;
    top: 120px;
    left: 20px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-null16788 {
  animation: animation-null16788 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-16788 {
  from {
    width: 296px;
    height: 92px;
    top: 120px;
    left: 20px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-16788 {
  animation: animationBack-16788 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I16788_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16788_128214433 {
  animation: nullClass-I16788_128214433 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16788_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16788_128214433 {
  animation: animation-nullI16788_128214433 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16788_128214433 {
  from {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16788_128214433 {
  animation: animationBack-I16788_128214433 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16788_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16788_128214434 {
  animation: nullClass-I16788_128214434 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16788_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16788_128214434 {
  animation: animation-nullI16788_128214434 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16788_128214434 {
  from {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16788_128214434 {
  animation: animationBack-I16788_128214434 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16788_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16788_128214438 {
  animation: nullClass-I16788_128214438 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16788_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16788_128214438 {
  animation: animation-nullI16788_128214438 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16788_128214438 {
  from {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16788_128214438 {
  animation: animationBack-I16788_128214438 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16788_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16788_128214435 {
  animation: nullClass-I16788_128214435 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16788_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16788_128214435 {
  animation: animation-nullI16788_128214435 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16788_128214435 {
  from {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16788_128214435 {
  animation: animationBack-I16788_128214435 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16788_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16788_128214436 {
  animation: nullClass-I16788_128214436 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16788_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 134px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16788_128214436 {
  animation: animation-nullI16788_128214436 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16788_128214436 {
  from {
    width: 134px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16788_128214436 {
  animation: animationBack-I16788_128214436 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16788_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16788_3474462889 {
  animation: nullClass-I16788_3474462889 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16788_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16788_3474462889 {
  animation: animation-nullI16788_3474462889 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16788_3474462889 {
  from {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16788_3474462889 {
  animation: animationBack-I16788_3474462889 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16788_3474462889_3463407150 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16788_3474462889_3463407150 {
  animation: nullClass-I16788_3474462889_3463407150 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI16788_3474462889_3463407150 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI16788_3474462889_3463407150 {
  animation: animation-nullI16788_3474462889_3463407150 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBg-I16788_3474462889_3463407150 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC);
  }
}
.bgClass-I16788_3474462889_3463407150 {
  animation: animationBg-I16788_3474462889_3463407150 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I16788_3474462889_3463407150 {
  from {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16788_3474462889_3463407150 {
  animation: animationBack-I16788_3474462889_3463407150 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-16789 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-16789 {
  animation: nullClass-16789 0.001s linear 0s normal forwards;
}
@keyframes animation-null16789 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 296px;
    height: 92px;
    top: 216px;
    left: 20px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-null16789 {
  animation: animation-null16789 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-16789 {
  from {
    width: 296px;
    height: 92px;
    top: 216px;
    left: 20px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-16789 {
  animation: animationBack-16789 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I16789_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16789_128214433 {
  animation: nullClass-I16789_128214433 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16789_128214433 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16789_128214433 {
  animation: animation-nullI16789_128214433 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16789_128214433 {
  from {
    width: 232px;
    height: 68px;
    top: 12px;
    left: 52px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16789_128214433 {
  animation: animationBack-I16789_128214433 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16789_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16789_128214434 {
  animation: nullClass-I16789_128214434 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16789_128214434 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16789_128214434 {
  animation: animation-nullI16789_128214434 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16789_128214434 {
  from {
    width: 232px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16789_128214434 {
  animation: animationBack-I16789_128214434 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16789_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16789_128214438 {
  animation: nullClass-I16789_128214438 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16789_128214438 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16789_128214438 {
  animation: animation-nullI16789_128214438 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16789_128214438 {
  from {
    width: 232px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16789_128214438 {
  animation: animationBack-I16789_128214438 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16789_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16789_128214435 {
  animation: nullClass-I16789_128214435 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16789_128214435 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16789_128214435 {
  animation: animation-nullI16789_128214435 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16789_128214435 {
  from {
    width: 232px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16789_128214435 {
  animation: animationBack-I16789_128214435 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16789_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16789_128214436 {
  animation: nullClass-I16789_128214436 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16789_128214436 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 114px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16789_128214436 {
  animation: animation-nullI16789_128214436 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16789_128214436 {
  from {
    width: 114px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16789_128214436 {
  animation: animationBack-I16789_128214436 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16789_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16789_3474462889 {
  animation: nullClass-I16789_3474462889 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16789_3474462889 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16789_3474462889 {
  animation: animation-nullI16789_3474462889 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16789_3474462889 {
  from {
    width: 24px;
    height: 24px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16789_3474462889 {
  animation: animationBack-I16789_3474462889 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16789_3474462889_3463405927 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16789_3474462889_3463405927 {
  animation: nullClass-I16789_3474462889_3463405927 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI16789_3474462889_3463405927 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI16789_3474462889_3463405927 {
  animation: animation-nullI16789_3474462889_3463405927 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBg-I16789_3474462889_3463405927 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC);
  }
}
.bgClass-I16789_3474462889_3463405927 {
  animation: animationBg-I16789_3474462889_3463405927 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I16789_3474462889_3463405927 {
  from {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16789_3474462889_3463405927 {
  animation: animationBack-I16789_3474462889_3463405927 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-16790 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-16790 {
  animation: nullClass-16790 0.001s linear 0s normal forwards;
}
@keyframes animation-null16790 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 304px;
    height: 332px;
    top: 0px;
    left: 336px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
    background: #f9fafbff;
  }
}
.animationClass-null16790 {
  animation: animation-null16790 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-16790 {
  from {
    width: 304px;
    height: 332px;
    top: 0px;
    left: 336px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
    background: #f9fafbff;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-16790 {
  animation: animationBack-16790 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-16791 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-16791 {
  animation: nullClass-16791 0.001s linear 0s normal forwards;
}
@keyframes animation-null16791 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 264px;
    height: 284px;
    top: 20px;
    left: 20px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
}
.animationClass-null16791 {
  animation: animation-null16791 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-16791 {
  from {
    width: 264px;
    height: 284px;
    top: 20px;
    left: 20px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 8px 8px 8px 8px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-16791 {
  animation: animationBack-16791 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I16791_128219897 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219897 {
  animation: nullClass-I16791_128219897 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219897 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 100px;
    top: 172px;
    left: 12px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16791_128219897 {
  animation: animation-nullI16791_128219897 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_128219897 {
  from {
    width: 240px;
    height: 100px;
    top: 172px;
    left: 12px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219897 {
  animation: animationBack-I16791_128219897 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_128219898 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219898 {
  animation: nullClass-I16791_128219898 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219898 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16791_128219898 {
  animation: animation-nullI16791_128219898 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_128219898 {
  from {
    width: 240px;
    height: 68px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219898 {
  animation: animationBack-I16791_128219898 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_128219899 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219899 {
  animation: nullClass-I16791_128219899 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219899 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16791_128219899 {
  animation: animation-nullI16791_128219899 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_128219899 {
  from {
    width: 240px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219899 {
  animation: animationBack-I16791_128219899 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_128219900 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219900 {
  animation: nullClass-I16791_128219900 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219900 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16791_128219900 {
  animation: animation-nullI16791_128219900 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_128219900 {
  from {
    width: 240px;
    height: 40px;
    top: 28px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219900 {
  animation: animationBack-I16791_128219900 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_128219901 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219901 {
  animation: nullClass-I16791_128219901 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219901 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 140px;
    height: 20px;
    top: 80px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16791_128219901 {
  animation: animation-nullI16791_128219901 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_128219901 {
  from {
    width: 140px;
    height: 20px;
    top: 80px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219901 {
  animation: animationBack-I16791_128219901 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_128219902 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219902 {
  animation: nullClass-I16791_128219902 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219902 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 54px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16791_128219902 {
  animation: animation-nullI16791_128219902 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_128219902 {
  from {
    width: 54px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219902 {
  animation: animationBack-I16791_128219902 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_128219902_3287433377 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219902_3287433377 {
  animation: nullClass-I16791_128219902_3287433377 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI16791_128219902_3287433377 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 54px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16791_128219902_3287433377 {
  animation: animation-nullI16791_128219902_3287433377 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I16791_128219902_3287433377 {
  from {
    width: 54px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219902_3287433377 {
  animation: animationBack-I16791_128219902_3287433377 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I16791_128219903 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219903 {
  animation: nullClass-I16791_128219903 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219903 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 74px;
    height: 20px;
    top: 0px;
    left: 66px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16791_128219903 {
  animation: animation-nullI16791_128219903 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_128219903 {
  from {
    width: 74px;
    height: 20px;
    top: 0px;
    left: 66px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219903 {
  animation: animationBack-I16791_128219903 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_128219903_3287433289 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219903_3287433289 {
  animation: nullClass-I16791_128219903_3287433289 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI16791_128219903_3287433289 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 74px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16791_128219903_3287433289 {
  animation: animation-nullI16791_128219903_3287433289 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I16791_128219903_3287433289 {
  from {
    width: 74px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219903_3287433289 {
  animation: animationBack-I16791_128219903_3287433289 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-I16791_128219895 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219895 {
  animation: nullClass-I16791_128219895 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219895 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 136px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 6px 6px 6px 6px;
  }
}
.animationClass-nullI16791_128219895 {
  animation: animation-nullI16791_128219895 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_128219895 {
  from {
    width: 240px;
    height: 136px;
    top: 12px;
    left: 12px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 6px 6px 6px 6px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219895 {
  animation: animationBack-I16791_128219895 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_128219896 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_128219896 {
  animation: nullClass-I16791_128219896 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_128219896 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 240px;
    height: 136px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16791_128219896 {
  animation: animation-nullI16791_128219896 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBg-I16791_128219896 {
  from {
    opacity: 1;
  }
  to {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./images/image1.png");
    opacity: 1;
  }
}
.bgClass-I16791_128219896 {
  animation: animationBg-I16791_128219896 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-I16791_128219896 {
  from {
    width: 240px;
    height: 136px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_128219896 {
  animation: animationBack-I16791_128219896 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_2647402504 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_2647402504 {
  animation: nullClass-I16791_2647402504 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16791_2647402504 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 48px;
    height: 48px;
    top: 80px;
    left: 8px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 9999px 9999px 9999px 9999px;
    backdrop-filter: blur(4px);
  }
}
.animationClass-nullI16791_2647402504 {
  animation: animation-nullI16791_2647402504 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16791_2647402504 {
  from {
    width: 48px;
    height: 48px;
    top: 80px;
    left: 8px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 9999px 9999px 9999px 9999px;
    backdrop-filter: blur(4px);
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_2647402504 {
  animation: animationBack-I16791_2647402504 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16791_2647402504_137712743 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16791_2647402504_137712743 {
  animation: nullClass-I16791_2647402504_137712743 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI16791_2647402504_137712743 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 48px;
    height: 48px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
}
.animationClass-nullI16791_2647402504_137712743 {
  animation: animation-nullI16791_2647402504_137712743 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBg-I16791_2647402504_137712743 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(https://firebasestorage.googleapis.com/v0/b/figma-plugin-a7287.appspot.com/o/user-images%2F26-jan-2024%2Fabiywasihun1706244140275%2Fimage-I1-6791_2647-402504_1377-12743.png?alt=media&token=0);
  }
}
.bgClass-I16791_2647402504_137712743 {
  animation: animationBg-I16791_2647402504_137712743 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I16791_2647402504_137712743 {
  from {
    width: 48px;
    height: 48px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16791_2647402504_137712743 {
  animation: animationBack-I16791_2647402504_137712743 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-112871 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112871 {
  animation: nullClass-112871 0.001s linear 0s normal forwards;
}
@keyframes animation-null112871 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 320px;
    height: 70px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null112871 {
  animation: animation-null112871 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112871 {
  from {
    width: 320px;
    height: 70px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112871 {
  animation: animationBack-112871 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112872 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112872 {
  animation: nullClass-112872 0.001s linear 0s normal forwards;
}
@keyframes animation-null112872 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 84px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-null112872 {
  animation: animation-null112872 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112872 {
  from {
    width: 84px;
    height: 20px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112872 {
  animation: animationBack-112872 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112873 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112873 {
  animation: nullClass-112873 0.001s linear 0s normal forwards;
}
@keyframes animation-null112873 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 320px;
    height: 44px;
    top: 26px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 8px 8px 8px 8px;
    background: #ffffffff;
    box-sizing: border-box;
    border-left: 1px solid #d6bbfbff;
    border-right: 1px solid #d6bbfbff;
    border-top: 1px solid #d6bbfbff;
    border-bottom: 1px solid #d6bbfbff;
    box-shadow:
      0px 0px 0px 4px rgba(158, 119, 237, 0.23999999463558197),
      0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
}
.animationClass-null112873 {
  animation: animation-null112873 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112873 {
  from {
    width: 320px;
    height: 44px;
    top: 26px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 8px 8px 8px 8px;
    background: #ffffffff;
    box-sizing: border-box;
    border-left: 1px solid #d6bbfbff;
    border-right: 1px solid #d6bbfbff;
    border-top: 1px solid #d6bbfbff;
    border-bottom: 1px solid #d6bbfbff;
    box-shadow:
      0px 0px 0px 4px rgba(158, 119, 237, 0.23999999463558197),
      0px 1px 2px 0px rgba(16, 24, 40, 0.05000000074505806);
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112873 {
  animation: animationBack-112873 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112879 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112879 {
  animation: nullClass-112879 0.001s linear 0s normal forwards;
}
@keyframes animation-null112879 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 82px;
    height: 44px;
    top: 0px;
    left: 238px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null112879 {
  animation: animation-null112879 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112879 {
  from {
    width: 82px;
    height: 44px;
    top: 0px;
    left: 238px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112879 {
  animation: animationBack-112879 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112880 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112880 {
  animation: nullClass-112880 0.001s linear 0s normal forwards;
}
@keyframes animation-null112880 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 34px;
    height: 24px;
    top: 10px;
    left: 14px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-null112880 {
  animation: animation-null112880 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112880 {
  from {
    width: 34px;
    height: 24px;
    top: 10px;
    left: 14px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112880 {
  animation: animationBack-112880 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112881 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112881 {
  animation: nullClass-112881 0.001s linear 0s normal forwards;
}
@keyframes animation-null112881 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 20px;
    height: 20px;
    top: 12px;
    left: 48px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null112881 {
  animation: animation-null112881 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112881 {
  from {
    width: 20px;
    height: 20px;
    top: 12px;
    left: 48px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112881 {
  animation: animationBack-112881 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I112881_3463404534 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I112881_3463404534 {
  animation: nullClass-I112881_3463404534 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI112881_3463404534 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 11.66650390625px;
    height: 6.6666259765625px;
    top: 7.5px;
    left: 5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI112881_3463404534 {
  animation: animation-nullI112881_3463404534 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBg-I112881_3463404534 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACJSURBVHgBfZC9DYMwEEbvPm6QjJAVUqfKCC6jzBOl9AhUqRkBRmAEamQOfOJHYH6usp/es6Xjr//fQW3zdq+aLmb2IKIemZQGLuVMC4bkCAFOeyKJ4CiaZYpOp3C8QGiBeAsdHh/3rFJZdeS8eWkVGUtlY7z7forUQCLvgnVk51Q+nZ/Pb2dbGwCb2VbBx+a4bAAAAABJRU5ErkJggg==);
  }
}
.bgClass-I112881_3463404534 {
  animation: animationBg-I112881_3463404534 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I112881_3463404534 {
  from {
    width: 11.66650390625px;
    height: 6.6666259765625px;
    top: 7.5px;
    left: 5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I112881_3463404534 {
  animation: animationBack-I112881_3463404534 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-112874 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112874 {
  animation: nullClass-112874 0.001s linear 0s normal forwards;
}
@keyframes animation-null112874 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 238px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null112874 {
  animation: animation-null112874 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112874 {
  from {
    width: 238px;
    height: 44px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112874 {
  animation: animationBack-112874 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112875 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112875 {
  animation: nullClass-112875 0.001s linear 0s normal forwards;
}
@keyframes animation-null112875 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 200px;
    height: 24px;
    top: 10px;
    left: 14px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null112875 {
  animation: animation-null112875 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112875 {
  from {
    width: 200px;
    height: 24px;
    top: 10px;
    left: 14px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112875 {
  animation: animationBack-112875 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112876 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112876 {
  animation: nullClass-112876 0.001s linear 0s normal forwards;
}
@keyframes animation-null112876 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 11px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-null112876 {
  animation: animation-null112876 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112876 {
  from {
    width: 11px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112876 {
  animation: animationBack-112876 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112877 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112877 {
  animation: nullClass-112877 0.001s linear 0s normal forwards;
}
@keyframes animation-null112877 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 181px;
    height: 24px;
    top: 0px;
    left: 19px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-null112877 {
  animation: animation-null112877 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112877 {
  from {
    width: 181px;
    height: 24px;
    top: 0px;
    left: 19px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112877 {
  animation: animationBack-112877 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-112878 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112878 {
  animation: nullClass-112878 0.001s linear 0s normal forwards;
}
@keyframes animation-null112878 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 16px;
    height: 16px;
    top: 14px;
    left: 222px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null112878 {
  animation: animation-null112878 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112878 {
  from {
    width: 16px;
    height: 16px;
    top: 14px;
    left: 222px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112878 {
  animation: animationBack-112878 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I112878_105467 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I112878_105467 {
  animation: nullClass-I112878_105467 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI112878_105467 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 16px;
    height: 16px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI112878_105467 {
  animation: animation-nullI112878_105467 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-I112878_105467 {
  from {
    width: 16px;
    height: 16px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I112878_105467 {
  animation: animationBack-I112878_105467 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes nullClass-I112878_105467_3463405412 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I112878_105467_3463405412 {
  animation: nullClass-I112878_105467_3463405412 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI112878_105467_3463405412 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 14.666748046875px;
    height: 14.66668701171875px;
    top: 1.3333333730697632px;
    left: 1.3333333730697632px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI112878_105467_3463405412 {
  animation: animation-nullI112878_105467_3463405412 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBg-I112878_105467_3463405412 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHiSURBVHgBhVNLctNAEO1uCRasfARzA3EC7DWlBB2AyNqQiCxiThDnBtECYtjInwMIcFjbN4hvgI6gHUVlZpoeKVIURal0lVRST3/fe4PwhH1JM48cxyPUhVKQn0bBvi8Ou45v6825YZjKwaATmjOYi/joYNFbIE2zwT/XzdiAJ78JGNjFkb+rpvntEekpIoaIsH+p1DiKguJBAel8wwYHbG7HcRTk0GNXaTZEcraAmJ8c+eOmwNXq1wQBU9bqdZ18vwoWiLw4/uBf1EWA3BsAk8Th4YzuqpzLe1En24LMMAOGhFkv7ffXdfbenlUxJiGkM/tP32U/SR5qfZvUo7KGQjp/jkN/ZrvYvYlfHDa7GNqxgGyZcpWrh8QIbZo+RQc/GmyW1yEze8JA08CCO19toKKZu3Td23z585Jlf7tKl75yUtEIOdrZV+BsRo8CjLmUdcYnoT9t+6u15VwERh+jd3sBqQAyo/45VN71GIfPpH2pTrpzlaiWFLWNaCbPpO2qYnjCaEpaGyHN15utVB2yfkZIjrNFxuI49N9Yn1sf/lUqeEXuFhz3z3x1vbC01sxI4ohc960VFlgpaxXUeY8uU6lKFmEhDtv+Eie5I1YbbT/CE2YvEIKWkWFghKlTAbsv7j/daPKdYsI/oAAAAABJRU5ErkJggg==);
  }
}
.bgClass-I112878_105467_3463405412 {
  animation: animationBg-I112878_105467_3463405412 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes animationBack-I112878_105467_3463405412 {
  from {
    width: 14.666748046875px;
    height: 14.66668701171875px;
    top: 1.3333333730697632px;
    left: 1.3333333730697632px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I112878_105467_3463405412 {
  animation: animationBack-I112878_105467_3463405412 0.20000000298023224s linear
    0s normal forwards;
}
@keyframes nullClass-14286 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-14286 {
  animation: nullClass-14286 0.001s linear 0s normal forwards;
}
@keyframes animation-null14286 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 20px;
    height: 20px;
    top: 12px;
    left: 141px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null14286 {
  animation: animation-null14286 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-14286 {
  from {
    width: 20px;
    height: 20px;
    top: 12px;
    left: 141px;
    opacity: 1;
    z-index: 2;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-14286 {
  animation: animationBack-14286 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I14286_3465410041 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I14286_3465410041 {
  animation: nullClass-I14286_3465410041 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI14286_3465410041 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 18.333328247070312px;
    height: 18.33331298828125px;
    top: 1.6666666269302368px;
    left: 1.6666666269302368px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI14286_3465410041 {
  animation: animation-nullI14286_3465410041 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBg-I14286_3465410041 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHNSURBVHgBrVW7UsJAFL27yIxjY0rGRuwcGxi0N/5BfPXwBcgXYL5A/ALofeEXEHrFWGmJlS00jjPCrvfkwTAxgZDxzCy7yV7O7r17zkbQEpRNy1gnMjD+Jhq5Tne0KF4kkazRWp2HZtDm4SpS19wcJh8uJdw3Ty1Bos1DQ/OOJImuJv0RhJc0qTLPF7kNpzS1B859J5HwwDxrcncJIv5jIxocomKeVwWpJogR/+Tc2n8IESRJt7W38s9RXDrz4LIUc5TrgZQzOH527rozQkzmeVKRpDRk86Rc6xeMJzTZwYFJPEjKm9pbaWqnJQMQq0g0eIhDvPC5vB9dR6pJNVuEgXPT4Q5SOvS4IBHsXpB+pYzgGj5yZ4ILOzTCDCgjICH0G1JuSvpngHAUrLJNGcH1L6H/UmosA286XAeLMkKye7hzZ7Jh9LkZFfOkSisChoDk4G+fnDxRtsjzrbyCWNOS+W5RTUgOl8WMEFvllGvYZZ7yvTSkobuihsiFAZ/Dt/et4h6saLFHrUJxd8zv3KQ0cyQf+CgL/Gizj1vhnIgLDm8S6Et7+vRFDyUEh+ddbfy+Fl4KiYRhOvA3LInHyLTDrY+6x93esYQR8pU+Ab9ccNFMRiixywAAAABJRU5ErkJggg==);
  }
}
.bgClass-I14286_3465410041 {
  animation: animationBg-I14286_3465410041 0.20000000298023224s linear 0s normal
    forwards;
}
@keyframes animationBack-I14286_3465410041 {
  from {
    width: 18.333328247070312px;
    height: 18.33331298828125px;
    top: 1.6666666269302368px;
    left: 1.6666666269302368px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I14286_3465410041 {
  animation: animationBack-I14286_3465410041 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-16784 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-16784 {
  animation: nullClass-16784 0.001s linear 0s normal forwards;
}
@keyframes animation-null16784 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-null16784 {
  animation: animation-null16784 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-16784 {
  from {
    width: 110px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-16784 {
  animation: animationBack-16784 0.20000000298023224s linear 0s normal forwards;
}
@keyframes nullClass-I16784_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16784_3287433397 {
  animation: nullClass-I16784_3287433397 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16784_3287433397 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 82px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-nullI16784_3287433397 {
  animation: animation-nullI16784_3287433397 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16784_3287433397 {
  from {
    width: 82px;
    height: 24px;
    top: 0px;
    left: 0px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16784_3287433397 {
  animation: animationBack-I16784_3287433397 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16784_3468468154 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16784_3468468154 {
  animation: nullClass-I16784_3468468154 0.001s linear 0s normal forwards;
}
@keyframes animation-nullI16784_3468468154 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 90px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
}
.animationClass-nullI16784_3468468154 {
  animation: animation-nullI16784_3468468154 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes animationBack-I16784_3468468154 {
  from {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 90px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16784_3468468154 {
  animation: animationBack-I16784_3468468154 0.20000000298023224s linear 0s
    normal forwards;
}
@keyframes nullClass-I16784_3468468154_3463404558 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-I16784_3468468154_3463404558 {
  animation: nullClass-I16784_3468468154_3463404558 0.001s linear 0s normal
    forwards;
}
@keyframes animation-nullI16784_3468468154_3463404558 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 11.666656494140625px;
    height: 6.6669921875px;
    top: 7.5px;
    left: 5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.animationClass-nullI16784_3468468154_3463404558 {
  animation: animation-nullI16784_3468468154_3463404558 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBg-I16784_3468468154_3463404558 {
  from {
    opacity: 1;
  }
  to {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBdY2xDYQwEAS99x98JcRPQk5AAQQUQEBVBBRACQQ0gAQhAUVQAPKCkSwsY19k38zoPiowRdX8kyT7beu0+0xCMoiRX87m7XOE5AdCaeh86NvlFbgywfyG19+PEJIttHs3Qkz2L9tIQOlishmzM4yKSohO5EAZk91IDkk1WJ+5SFl5rTC8FwAAAABJRU5ErkJggg==);
  }
}
.bgClass-I16784_3468468154_3463404558 {
  animation: animationBg-I16784_3468468154_3463404558 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes animationBack-I16784_3468468154_3463404558 {
  from {
    width: 11.666656494140625px;
    height: 6.6669921875px;
    top: 7.5px;
    left: 5px;
    opacity: 1;
    z-index: 0;
    transform: rotate(0deg);
    transform-origin: 0px 0px;
    overflow: hidden;
    box-sizing: border-box;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-I16784_3468468154_3463404558 {
  animation: animationBack-I16784_3468468154_3463404558 0.20000000298023224s
    linear 0s normal forwards;
}
@keyframes nullClass-112882 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-112882 {
  animation: nullClass-112882 0.001s linear 0s normal forwards;
}
@keyframes animation-null112882 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    width: 320px;
    height: 20px;
    top: 76px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
}
.animationClass-null112882 {
  animation: animation-null112882 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animationBack-112882 {
  from {
    width: 320px;
    height: 20px;
    top: 76px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    text-align: left;
    line-height: 0px;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClassBack-112882 {
  animation: animationBack-112882 0.20000000298023224s linear 0s normal forwards;
}
@keyframes animation-19043618 {
  from {
    width: 1440px;
    height: 1722px;
    top: 80px;
    left: 0px;
    opacity: 1;
    z-index: 1;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 0px 0px 0px 0px;
    background: #f2ecd9ff;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.animationClass-19043618 {
  animation: animation-19043618 0.001s linear 0s normal forwards;
}
@keyframes nullClass-19043618 {
  from {
    opacity: 0;
    z-index: -10;
  }
  to {
    opacity: 0;
    z-index: -10;
  }
}
.nullAnimation-19043618 {
  animation: nullClass-19043618 0.20000000298023224s linear 0s normal forwards;
}
