.buttonsbutton-1-35bv{
	width: auto;
    padding: 0 .51rem;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: rotate(0deg);
    border-radius: 0px 24px 24px 0px;
    background: #e9d8a6ff;
}
.content-1-bg{
	display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 576px;
    height: 308px;
}
.input-1-14-bg {
    width: 181.5px;
    height: 48px;
    transform: rotate(0deg);
    border-radius: 24px 0px 0px 24px;
    background: #BC5538;
}
.budget-header{
    height: 100px;
}
.frame-14-event{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.budget__container{
    background: #E9D8A6;
    padding: 1rem;
    margin: 0px 7%;
    display: flex;
    border-radius: 24px;
    flex-direction: column;
    align-items: center;
}
.budget__dropdown{
    color: #9A3B1F;
    display: flex;
    gap: 1rem;
    align-items: center;
}
.dropdown__label{
    font-size: 23px;
    font-family: Mermaid;
    font-weight: 700;
}
.dropdown__select{
    border-radius: 1rem;
    padding: .61rem;
    font-size: 16px;
    color: #9A3B1F;
    font-weight: 600;
}
.dropdown__text{
    display: flex;
    align-items: center;
}
.budget__dropdown__text{
  font-family: Mermaid;  
}
.frame-13-sr {
    height: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 5% 5px 5%;
    transform: rotate(0deg);
    border-radius: 0px 0px 0px 0px;
}
.budget__section{
    margin: 2% 7% 0px 7%;
}
.budget__title{
    color: #8f250cff;
    font-size: 23px;
    font-family: Mermaid;
    padding-bottom: .61rem;
    margin-bottom: .61rem;
    border-bottom: 1px solid #8f250cff;
}
.budget__cards{
    display: flex;
    justify-content: start;
    gap: 2rem;
}
.budget__card{
    border-radius: 24px;
    border: 1px solid #8f250cff;
    display: flex;
    align-items: center;
    gap: .51rem;
    padding: .5rem;
}
.budget__quantitys{
    border-radius: 18px;
    border: 1px solid #8f250cff;
    display: flex;
    align-items: center;
    gap: .51rem;
    padding: .2rem 1rem;
}
.budget__minus,.budget__plus{
    cursor: pointer;
}
.is-disabled
{
  pointer-events: none;
  opacity: 0.7;
}
.cart_div{
    position: relative;
}
.cart_badge{
    background-color: #1119d1;
    color: white;
    padding: 1px 6px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: 18px;
    top: -6px;
}
.budget__buton{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    padding-bottom: 1rem;
    gap: 1rem;
}
.cancel__button{
    background: #fff;
    color: #000;
}
.heading-1-4209-ev {
    font-size: 29px;
    font-family: Mermaid;
    font-weight: 700;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.24px;
    line-height: 44px;
    color: #8f250cff;
}
.budget__detail{
    font-family: Mermaid;
    font-size: 17px;
}