.signup_continer{
	background: lightgray;
    position: absolute;
    width: 100%;
    height: 100%;
}
.signup_continer_2{
    background: lightgray;
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
}
.signup_modal{
	display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 50%;
    align-items: center;
    background: #F2ECD9;
    position: absolute;
    top: 18%;
    left: 25%;
}
.image-1-19-su{
	width: 347px;
    height: 450px;
    transform: rotate(0deg);
    overflow: hidden;
    border-radius: 24px 0 0 24px;
}
.signup_left_modal{
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
}
.form_title{
	font-size: 23px;
    font-family: Mermaid;
    font-weight: 700;
    letter-spacing: 0.24px;
    line-height: 44px;
    color: #8f250cff;
}
.input_fields{
	display: flex;
	gap: .4rem;
	margin-bottom: 1rem;
    flex-direction: column;
}
.textarea_field, .input_field{
	border: #8f250cff;
    width: 100%;
    padding: .41rem;
    border-radius: 24px;
}
.textarea_field{
    border-radius: 16px;
}
span{
    font-family: Avenir;
}
.buttons{
    display: flex;
}
.second_page_button{
    width: 80%;
}
.back_button{
    cursor: pointer;
}
.signup_button{
	font-size: 18px;
    font-family: Avenir;
    line-height: 24px;
    background: #8f250cff;
    color: white;
    text-align: center;
    cursor: pointer;
    border-radius: 24px;
}
.signup_login{
	text-decoration: underline;
    color: #8f250cff;
    font-family: Avenir;
    margin-top: 1rem;
}
.categories-su{
	gap: .31rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: .5rem;
}
.badge-1-su{
	height: 24px;
    transform: rotate(0deg);
    border-radius: 12px 12px 12px 12px;
    background: #ddd3b3;
    cursor: pointer;
}
.text-1-1873-su{
	font-size: 11px;
    padding: 1rem;
    font-family: Avenir;
    font-weight: 500;
    text-decoration: none;
    text-transform: none;
    line-height: 20px;
    color: black;
}
@media all and (min-width: 1024px){ }

@media all and (min-width: 768px) and (max-width: 1024px) { }

@media all and (min-width: 480px) and (max-width: 768px) {
 .signup_modal{
    width: 97%;
    top: 7%;
    left: 3%;
 }
 .signup_left_modal{
    align-items: center;
 }
 .signup_right_modal{
    display: none;
 }
}

@media all and (max-width: 480px) {
 .signup_modal{
    width: 97%;
    top: 7%;
    left: 3%;
 }
 .signup_left_modal{
    align-items: center;
 }
 .signup_right_modal{
    display: none;
 }
}